import axios from "axios";
import { useNavigate } from "react-router-dom";
import apiUrl from "../constants/api-constants";
import { ROUTES } from "../constants/routes-constants";
import { device } from "../services/deviceDetailsService";
import { setAuthenticated } from "../store/actions/data";
import { useAppDispatch } from "../store/reducers/store";
import useAuth from "./useAuth";

export const useTokenRefresh = () => {
    const { setAuth } = useAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const refreshToken = async () => {
        try {
            // Call your refresh token API
            const response = await axios.post(apiUrl.BASE_URL + apiUrl.auth.postRefreshToken,
                {
                    deviceType: device.getType(),
                    deviceAddress: device.getAddress()
                },
                {
                    withCredentials: true,
                });

            const { accessToken, tokenType, user } = response.data;

            // Update the context with the new tokens
            setAuth({
                accessToken: accessToken,
                tokenType: tokenType,
                user: user,
                roles: user.roles.map((x: any) => x.role)
            });

            // return accessToken & tokenType for attaching in the original request
            return { accessToken, tokenType };
        } catch (error) {
            console.error("Token refresh failed:", error);
            dispatch(setAuthenticated(false));
            navigate(ROUTES.INDEX);
            throw error;
        }
    };

    return { refreshToken };
};
