import BorderColorIcon from '@mui/icons-material/BorderColor';
import GridOnIcon from '@mui/icons-material/GridOn';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import TableActionButton from '../../../components/buttons/TableActionButton';
import PopoverDetails from '../../../components/popovers/PopoverDetails';
import { SelectOption } from '../../../constants/type-constants';
import { useVesselManagementService } from './useVesselManagementService';

interface VesselTableProps {
    vesselTableRows: any[]
    handleVesselEditClick: (id: any) => Promise<void>
    handleVesselMatrixClick: (id: any) => Promise<void>
}

const VesselTable: React.FC<VesselTableProps> = ({ vesselTableRows, handleVesselEditClick, handleVesselMatrixClick }) => {

    const { fetchDeckOptions } = useVesselManagementService();

    const [deckOptions, setDeckOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        fetchDeckOptions(setDeckOptions);
    }, []);

    const vesselTableColumns: GridColDef[] = [
        { field: 'slNo', headerName: 'Sl No' },
        { field: 'vesselName', headerName: 'Name', flex: 2 },
        { field: 'vesselRegNo', headerName: 'Reg No', flex: 2 },
        { field: 'vesselType', headerName: 'Type', flex: 1 },
        { field: 'baseGhat', headerName: 'Base Ghat', flex: 2 },
        { field: 'tripDays', headerName: 'Trip Days', flex: 3 },
        { field: 'owner', headerName: 'Owner', flex: 2 },
        {
            align: 'center',
            flex: 2,
            minWidth: 150,
            headerAlign: 'center',
            field: 'capacity',
            headerName: 'Capacity',
            sortable: false,
            filterable: false,
            renderCell: (params: any) => {
                const { id, deckItems } = params.row;
                const distinctDeckIds = Array.from(new Set(deckItems?.map((item: any) => item.deck.id)));
                return (
                    <div className='flex gap-2 justify-center items-center h-full'>
                        <PopoverDetails
                            buttonText={'View Capacity'}
                        >
                            {
                                distinctDeckIds.length > 0 ?
                                    distinctDeckIds.map((deckId: any) => {
                                        return (
                                            <table key={deckId} className='w-full' >
                                                <tbody key={deckId} className='text-sm'>
                                                    <tr key={deckId} className='border bg-sky-100'>
                                                        <td colSpan={2} className='px-2 py-1'>{deckOptions.find((deck: any) => deck.value == deckId)?.label}</td>
                                                    </tr>
                                                    {
                                                        deckItems.map((item: any) => {
                                                            if (item.deck.id == deckId) {
                                                                return (
                                                                    <tr key={deckId + item.id}>
                                                                        <td className='w-3/4 border px-2 py-1'>{item.item.itemType}({item.item.unitType})</td>
                                                                        <td className='w-1/4 border px-2 py-1'>{item.capacityValue}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        )
                                    }) : `Not Available`
                            }
                        </PopoverDetails>
                    </div>
                );
            },
        },
        {
            align: 'center',
            flex: 2,
            minWidth: 150,
            headerAlign: 'center',
            field: 'action',
            headerName: 'Action',
            sortable: false,
            filterable: false,
            renderCell: (params: any) => {
                const { id } = params.row;
                return (
                    <div className='flex gap-2 justify-center items-center h-full'>
                        <TableActionButton
                            type='primary'
                            onClick={async () => handleVesselEditClick(id)}>
                            <BorderColorIcon fontSize='small' />
                        </TableActionButton>
                        <TableActionButton
                            type='primary'
                            onClick={async () => handleVesselMatrixClick(id)}>
                            <GridOnIcon fontSize='small' />
                        </TableActionButton>
                    </div>
                );
            },
        }
    ]


    return (
        <DataGrid
            rows={vesselTableRows}
            columns={vesselTableColumns}
            autosizeOnMount
            autoHeight
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                },
            }}
            pageSizeOptions={[5, 10, 15]}
        />
    );
}

export default VesselTable