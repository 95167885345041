import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import DefaultButton from '../../../components/buttons/DefaultButton'
import apiUrl from '../../../constants/api-constants'
import { SelectOption, VesselLayoutSlot } from '../../../constants/type-constants'
import { useApiService } from '../../../hooks/useApiService'
import { handleError } from '../../../services/handleError'
import { useVesselMatrixService } from './useVesselMatrixService'

interface LayoutProps {
    rows: number
    columns: number
    items: SelectOption[]
    vesselId: string
    deck: string
    slots: VesselLayoutSlot[];
}

interface SlotItem {
    rowNo: number;
    colNo: number;
    item: {
        id: string;
        itemName: string;
    }
}

const itemBorder: any = {
    'Female': 'border-pink-500',
    'PWD': 'border-blue-500',
    'Senior Citizen': 'border-yellow-500',
    'Unreserved': 'border-gray-500',
    'Goods': 'border-green-500',
    'Bicycle': 'border-teal-500',
    'Two Wheeler': 'border-purple-500',
    'Four Wheeler': 'border-red-500',
};

export const getItemBorderClass = (itemName: string | undefined) => {
    if (itemName) {
        return itemBorder[itemName] || 'border-neutral-300';
    }
    return 'border-neutral-300';
};

const Layout: React.FC<LayoutProps> = ({ rows, columns, vesselId, items, deck, slots }) => {

    const { postAuthRequest } = useApiService();

    const [itemList, setItemList] = useState<SelectOption[]>(items);
    const [vesselLayoutSlots, setVesselLayoutSlots] = useState<VesselLayoutSlot[]>(slots);

    useEffect(() => {
        console.log("STATE: ");
        console.log(vesselLayoutSlots);
        console.log("INCOMING PAYLOAD: ");
        console.log(slots);
    }, [vesselLayoutSlots])

    useEffect(() => {
        setVesselLayoutSlots(slots);
        setItemList(items);
    }, [slots]);

    const [slotItemlist, setSlotItemList] = useState<SlotItem[]>(() => {
        const list: SlotItem[] = [];
        if (vesselLayoutSlots.length > 0) {
            vesselLayoutSlots.forEach(slot => {
                list.push({
                    rowNo: slot.rowNo,
                    colNo: slot.columnNo,
                    item: {
                        id: slot.item.id,
                        itemName: slot.item.itemName,
                    },
                });
            });
        }
        return list;
    });

    const handleLayoutInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const [_, row, col] = name.split('_');
        const rowNo = Number(row);
        const colNo = Number(col);

        if (isNaN(rowNo) || isNaN(colNo)) {
            console.error(`Invalid row or column number: ${rowNo}, ${colNo}`);
            return;
        }

        const existingSlotIndex = vesselLayoutSlots.findIndex(slot => slot.rowNo == rowNo && slot.columnNo == colNo);
        const updatedList = [...vesselLayoutSlots];

        if (existingSlotIndex != -1) {
            // if (value == '') {
            //     updatedList.splice(existingSlotIndex, 1);
            //     setVesselLayoutSlots(updatedList);
            //     return;
            // }
            updatedList[existingSlotIndex].seatNo = value;
            setVesselLayoutSlots(updatedList);

        } else {
            const slotItem = slotItemlist.find(slotItem => slotItem.rowNo == rowNo && slotItem.colNo == colNo);
            const newSeat = {
                rowNo: rowNo,
                columnNo: colNo,
                seatNo: value,
                borderColor: getItemBorderClass(slotItem?.item.itemName || itemList[0]?.label),
                item: {
                    id: slotItem?.item.id || itemList[0]?.value,
                    itemName: slotItem?.item.itemName || itemList[0]?.label,
                }
            };
            updatedList.push(newSeat);
            setVesselLayoutSlots(updatedList);
        }
    }

    const handleSlotItemChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        const item = itemList.find(item => item.value == value);
        const [_, row, col] = name.split('_');
        const rowNo = Number(row);
        const colNo = Number(col);

        const slotItemIndex = slotItemlist.findIndex(slotItem => slotItem.rowNo == rowNo && slotItem.colNo == colNo);
        if (slotItemIndex != -1) {
            const updatedList = [...slotItemlist];
            if (item) {
                updatedList[slotItemIndex].item = {
                    id: item.value,
                    itemName: item.label
                };
            }
            setSlotItemList(updatedList);
        } else {
            const updatedList = [...slotItemlist];
            if (item) {
                updatedList.push({
                    rowNo: rowNo,
                    colNo: colNo,
                    item: {
                        id: item.value,
                        itemName: item.label
                    }
                })
            }
            setSlotItemList(updatedList);
        }
        const slotIndex = vesselLayoutSlots.findIndex(seat => seat.rowNo == rowNo && seat.columnNo == colNo);
        if (slotIndex != -1) {
            const updatedList = [...vesselLayoutSlots];
            if (item) {
                updatedList[slotIndex].item = {
                    id: item.value,
                    itemName: item.label
                };
                updatedList[slotIndex].borderColor = getItemBorderClass(item.label || 'border-neutral-300');
            }
            setVesselLayoutSlots(updatedList);
        }
    }

    const handleLayoutSave = async () => {
        try {
            const postData = {
                vessel: {
                    id: vesselId
                },
                deck: {
                    id: deck
                },
                vesselLayoutSlots: vesselLayoutSlots
            }

            console.log(postData);

            // const response = await postAuthRequest(apiUrl.manage.vesselLayout.saveVesselLayout, postData);

            // const { message } = response.data;

            // toast.success(message);
        } catch (error) {
            handleError(error);
        }

    }

    const newMatrix = [];

    if (rows > 0 && columns > 0) {

        for (let i = 0; i < rows; i++) {
            const row = [];
            for (let j = 0; j < columns; j++) {
                row.push(
                    <div className={`border rounded ${vesselLayoutSlots.find(slot => slot.rowNo == i && slot.columnNo == j)?.borderColor || 'border-neutral-300'}`}
                        key={`cell_${i}_${j}`}>
                        <div>
                            <input
                                type={'text'}
                                id={`cell_${i}_${j}`}
                                name={`cell_${i}_${j}`}
                                className={`h-8 border rounded-t text-center px-2 w-20 
                                    ${vesselLayoutSlots.find(slot => slot.rowNo == i && slot.columnNo == j)?.borderColor || 'border-neutral-300'}`}
                                onChange={handleLayoutInputChange}
                                value={vesselLayoutSlots.find(slot => slot.rowNo == i && slot.columnNo == j)?.seatNo}
                            />
                        </div>
                        <div className='text-xs'>
                            <select
                                name={`item_${i}_${j}`} id={`item_${i}_${j}`}
                                className={`h-8 border rounded-b px-2 w-20 
                                    ${vesselLayoutSlots.find(slot => slot.rowNo == i && slot.columnNo == j)?.borderColor || 'border-neutral-300'} `}
                                value={slotItemlist.find(slotItem => slotItem.rowNo == i && slotItem.colNo == j)?.item.id}
                                onChange={handleSlotItemChange}>
                                {itemList.map(item => {
                                    return (
                                        <option key={item.value} value={item.value}>{item.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>

                );
            }

            newMatrix.push(
                <div className='flex gap-2 my-2' key={`row_${i}`}>
                    {row}
                </div>
            )
        }
    }

    return (
        <>
            <div className='overflow-x-scroll'>
                {newMatrix}
            </div>
            <div className='mb-4'>
                <DefaultButton
                    onClick={handleLayoutSave}
                    buttonText={'Save'}
                    disabledTime={1}
                />
            </div>
        </>
    )
}

export default Layout