import React from 'react';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import MapIcon from '@mui/icons-material/Map';
import RouteIcon from '@mui/icons-material/Route';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Body from '../../components/body/Body';
import { ROUTES } from '../../constants/routes-constants';
import MenuWrapper from '../../components/menus/MenuWrapper';
import Card from '../../components/cards/Card';

const adminLinks = [
    { name: 'Division Management', href: ROUTES.MANAGE.DIVISION, icon: DeviceHubIcon },
    { name: 'Ghat Management', href: ROUTES.MANAGE.GHAT, icon: MapIcon },
    { name: 'Route Management', href: ROUTES.MANAGE.ROUTE, icon: RouteIcon },
    { name: 'Trip Management', href: ROUTES.MANAGE.TRIP, icon: ModeOfTravelIcon },
    { name: 'Vessel Management', href: ROUTES.MANAGE.VESSEL, icon: DirectionsBoatIcon },
    { name: 'Rate Management', href: ROUTES.MANAGE.RATE, icon: CurrencyRupeeRoundedIcon },
    { name: 'Trip Settings', href: ROUTES.MANAGE.TRIP_SETTINGS, icon: SettingsRoundedIcon },
]

const SysAdminDashboard: React.FC = () => {

    return (
        <Body>
            <div className='my-2'>
                <div className='flex flex-wrap justify-between items-center'>
                    <h1 className="text-3xl font-medium mb-0">System Admin - Dashboard</h1>
                    <div className='flex items-center gap-3'>
                        <MenuWrapper
                            id={'master-manage-menu'}
                            title='Master Management'
                            items={adminLinks}
                        />
                    </div>
                </div>
            </div>

            <div className='flex-1 flex flex-col'>
                {/* Key Metrics */}
                <div className='flex flex-wrap mb-4'>
                    <div className='w-full lg:w-1/4 lg:pr-4'>
                        <Card addClass={['p-2']}>
                            <div>
                                <h2 className='text-lg'>Total Divisions</h2>
                                <p className='text-2xl font-medium'>10</p>
                            </div>
                        </Card>
                    </div>
                    <div className='w-full lg:w-1/4 lg:pr-4'>
                        <Card addClass={['p-2']}>
                            <div>
                                <h2 className='text-lg'>Total Ghats</h2>
                                <p className='text-2xl font-medium'>35</p>
                            </div>
                        </Card>
                    </div>
                    <div className='w-full lg:w-1/4 lg:pr-4'>
                        <Card addClass={['p-2']}>
                            <div>
                                <h2 className='text-lg'>Total Routes</h2>
                                <p className='text-2xl font-medium'>25</p>
                            </div>
                        </Card>
                    </div>
                    <div className='w-full lg:w-1/4'>
                        <Card addClass={['p-2']}>
                            <div>
                                <h2 className='text-lg'>Total Vessels</h2>
                                <p className='text-2xl font-medium'>15</p>
                            </div>
                        </Card>
                    </div>
                </div>

                {/* Recent Activities */}
                <div className='mb-4'>
                    <Card>
                        <h2 className='text-lg font-semibold mb-4'>Recent Activities</h2>
                        <ul className='space-y-2'>
                            <li className='flex justify-between'>
                                <span>Route 101 updated</span>
                                <span className='text-gray-500 text-sm'>10 mins ago</span>
                            </li>
                            <li className='flex justify-between'>
                                <span>Division Guwahati added</span>
                                <span className='text-gray-500 text-sm'>1 hour ago</span>
                            </li>
                            <li className='flex justify-between'>
                                <span>Vessel Assam Express scheduled</span>
                                <span className='text-gray-500 text-sm'>2 hours ago</span>
                            </li>
                        </ul>
                    </Card>
                </div>
                {/* User Statistics */}
                <div className='mb-4'>
                    <Card>
                        <h2 className='text-lg font-semibold mb-4'>User Statistics</h2>
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                            <div className='bg-slate-100 py-4 px-6 rounded-lg'>
                                <h4>Total Users</h4>
                                <p className='text-2xl font-medium'>3,450</p>
                                <p className='text-sm text-gray-500'>Registered</p>
                            </div>
                            <div className='bg-slate-100 py-4 px-6 rounded-lg'>
                                <h4>Active Users</h4>
                                <p className='text-2xl font-medium'>2,980</p>
                                <p className='text-sm text-gray-500'>Last 24 hours</p>
                            </div>
                            <div className='bg-slate-100 py-4 px-6 rounded-lg'>
                                <h4>New Registrations</h4>
                                <p className='text-2xl font-medium'>150</p>
                                <p className='text-sm text-gray-500'>Last 24 hours</p>
                            </div>
                            <div className='bg-slate-100 py-4 px-6 rounded-lg'>
                                <h4>User Retention Rate</h4>
                                <p className='text-2xl font-medium'>85%</p>
                                <p className='text-sm text-gray-500'>Last 30 days</p>
                            </div>
                            <div className='bg-slate-100 py-4 px-6 rounded-lg'>
                                <h4>Churn Rate</h4>
                                <p className='text-2xl font-medium'>5%</p>
                                <p className='text-sm text-gray-500'>Last 30 days</p>
                            </div>
                            <div className='bg-slate-100 py-4 px-6 rounded-lg'>
                                <h4>Average Session Duration</h4>
                                <p className='text-2xl font-medium'>12 min</p>
                                <p className='text-sm text-gray-500'>Last 24 hours</p>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </Body>
    )
}

export default SysAdminDashboard;
