import React from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

interface TicketTypeClickableProps {
    icon: any;
    availability: string;
    hoverText: string;
    unitLabel: string;
}

const TicketTypeClickable: React.FC<TicketTypeClickableProps> = ({ icon, availability, hoverText, unitLabel }) => {
    return (
        <div className='px-2 mb-4 cursor-pointer relative'>
            <div className="bg-red-50 text-center px-4 py-1 rounded-lg outline outline-2 outline-red-100">
                <div className='w-10 h-10 flex mx-auto'>
                    <img src={icon} className='w-full' alt="" />
                </div>
                <div className="flex flex-col items-center justify-center">
                    <div className='text-xs'>Available</div>
                    <div className='font-semibold'>{availability}</div>
                    <div className='text-xs'>{unitLabel}</div>
                </div>
            </div>
            <div className='absolute px-2 top-0 left-0 w-full h-full opacity-0 hover:opacity-100 duration-300 hover:backdrop:blur-md'>
                <div className='w-full h-full rounded-lg bg-red-50 bg-opacity-100'>
                    <div className='flex flex-col justify-center items-center h-full text-red-400'>
                        <AddRoundedIcon fontSize='large' />
                        <div className='font-semibold'>{hoverText}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TicketTypeClickable