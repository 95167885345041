import toast from "react-hot-toast";
import apiUrl from "../../../constants/api-constants";
import { SelectOption } from "../../../constants/type-constants";
import { useApiService } from "../../../hooks/useApiService";
import { useEnumService } from "../../../hooks/useEnumService";
import { handleError } from "../../../services/handleError";
import { SetStateAction } from "react";

export const useVesselManagementService = () => {

    const {
        fetchAllDivisions,
        fetchVesselTypes,
        fetchStatusList,
        fetchOwners,
        fetchNightNavigations,
        fetchAllDecks,
        fetchAllWeekDays,
    } = useEnumService();

    const {
        fetchAuthRequest,
        postAuthRequest
    } = useApiService();

    // ======================== DIVISIONS =========================

    const fetchDivisionOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption: React.Dispatch<React.SetStateAction<string>>,
        division: string
    ) => {
        try {
            const options = await fetchAllDivisions();
            updateState(options);
            setOption(division != '' ? division : options[0]?.value);

        } catch (error) {
            handleError(error);
        }
    }

    // ========================== GHATS ==========================

    const fetchGhatOptions = async (
        division: string,
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
    ) => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.manage.ghat.getGhatsByDivision,
                {
                    divisionId: division
                }
            );

            if (response) {
                const { data } = response;
                const options: any = [
                    { value: '', label: 'Select Ghat', }
                ]

                data.forEach((ghat: any, index: number) => {
                    options.push({
                        value: ghat.id,
                        label: ghat.ghatName
                    })
                });

                updateState(options);
            }
        } catch (error) {
            handleError(error);
        }
    }

    // ======================== VESSEL TYPES =======================

    const fetchVesselTypeOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption: React.Dispatch<React.SetStateAction<string>>
    ) => {
        const options: any[] = await fetchVesselTypes();
        if (options) {
            updateState(options);
            setOption(options[0]?.value);
        }
    }

    // ========================== STATUS ==========================

    const fetchStatusOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption: React.Dispatch<React.SetStateAction<string>>
    ) => {
        const options: any[] = await fetchStatusList();
        if (options) {
            updateState(options);
            setOption(options[0]?.value);
        }
    }

    // ========================= OWNER ============================

    const fetchOwnerOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption: React.Dispatch<React.SetStateAction<string>>
    ) => {
        const options: any[] = await fetchOwners();
        if (options) {
            updateState(options);
            setOption(options[0]?.value);
        }
    }

    // ===================== NIGHT NAVIGATION =====================

    const fetchNightNaviationOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption: React.Dispatch<React.SetStateAction<string>>
    ) => {
        const options: any[] = await fetchNightNavigations();
        if (options) {
            updateState(options);
            setOption(options[0]?.value);
        }
    }

    // =========================== DECK ===========================

    const fetchDeckOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>
    ) => {
        const options: any[] = await fetchAllDecks();
        if (options) {
            updateState(options);
        }
    }

    // ======================== WEEKDAYS =========================

    const fetchWeekdaysOptions = async (
        setWeekdays: React.Dispatch<React.SetStateAction<any[]>>,
        setSelectedDays: React.Dispatch<React.SetStateAction<any>>
    ) => {
        try {
            const response: any = await fetchAllWeekDays();
            if (response) {
                const { data } = response;

                // Initialize selection status
                const initialSelection: any = {};
                data.forEach((day: any) => {
                    initialSelection[day.id] = false;
                });

                setWeekdays(data);
                setSelectedDays(initialSelection);
            }
        } catch (error) {
            handleError(error);
        }
    }

    // =================== VESSELS BY DIVISION ====================

    const fetchVesselsByDivisions = async (
        division: string,
        setVesselTableRows: React.Dispatch<React.SetStateAction<any[]>>,
        setVesselList: React.Dispatch<React.SetStateAction<any[]>>
    ) => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.manage.vessel.getVesselsByDivision,
                {
                    divisionId: division
                }
            );

            if (response) {
                const { data } = response;
                const rows: any = [];
                data.forEach((vessel: any, index: number) => {
                    rows.push({
                        slNo: index + 1,
                        id: vessel.id,
                        vesselName: vessel.name,
                        baseGhat: vessel.baseGhat.ghatName,
                        tripDays: vessel.tripDays.map((day: any) => ` ${day.dayName.slice(0, 3)}`),
                        vesselRegNo: vessel.regNo,
                        owner: vessel.owner,
                        vesselType: vessel.vesselType,
                        hasNightNavigation: vessel.hasNightNavigation,
                        status: vessel.status,
                        deckItems: vessel.vesselDeckItems
                    });
                });

                setVesselTableRows(rows);
                setVesselList(data);
            }
        } catch (error) {
            handleError(error);
        }
    }

    // ======================= SAVE VESSEL =======================

    const saveVessel = async (
        postData: any,
        division: string,
        setVesselTableRows: React.Dispatch<React.SetStateAction<any[]>>,
        setVesselList: React.Dispatch<React.SetStateAction<any[]>>,
        handleVesselModalClose: () => void
    ) => {
        const response: any = await postAuthRequest(
            apiUrl.manage.vessel.saveVessel,
            postData
        )

        if (response) {
            const { message } = response.data;
            toast.success(message);
            fetchVesselsByDivisions(division, setVesselTableRows, setVesselList)
            handleVesselModalClose();
        }
    }

    return {
        // --- options ------
        fetchDivisionOptions,
        fetchGhatOptions,
        fetchVesselTypeOptions,
        fetchStatusOptions,
        fetchOwnerOptions,
        fetchNightNaviationOptions,
        fetchDeckOptions,
        fetchWeekdaysOptions,
        // --- ./options -----

        fetchVesselsByDivisions,
        saveVessel
    };
}