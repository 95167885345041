import apiUrl from "../constants/api-constants";
import { useApiService } from "./useApiService";
import { handleError } from "../services/handleError";

export const useEnumService = () => {
    const { fetchAuthRequest } = useApiService();

    const fetchAllWeekDays = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.common.master.getAllWeekDays
            );

            if (response) {
                return response;
            } else {
                return null;
            }
        } catch (error) {
            handleError(error);
        }
    }

    const fetchAllItems = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.common.master.getAllItems
            );

            if (response) {
                return response;
            } else {
                return null;
            }
        } catch (error) {
            handleError(error);
        }
    }

    const fetchAllDecks = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.common.master.getAllDecks
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: any, index: number) => {
                    options.push({
                        value: item.id,
                        label: item.deckNo
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchVesselTypes = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.common.enums.vesselTypes
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    })
                });

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchUnitTypes = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.common.enums.unitTypes
            );

            if (response) {
                return response;
            }
            return null;
        } catch (error) {
            handleError(error);
        }
    }

    const fetchStatusList = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.common.enums.statuses
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchServiceTypes = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.common.enums.serviceTypes
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchPwd = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.common.enums.pwd
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchOwners = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.common.enums.owners
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchNightNavigations = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.common.enums.nightNavigations
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchModes = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.common.enums.modes
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchGenders = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.common.enums.genders
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchDeviceTypes = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.common.enums.deviceTypes
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchBookingModes = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.common.enums.bookingModes
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchGhatsByDivision = async (divisionId: any) => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.manage.ghat.getGhatsByDivision,
                {
                    divisionId: divisionId
                }
            )

            if (response) {
                const options: any = [];
                response.data.forEach((ghat: any) => {
                    options.push({
                        value: ghat.id,
                        label: ghat.ghatName
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchAllGhats = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.manage.ghat.getGhats
            )

            if (response) {
                const options: any = [];
                response.data.forEach((ghat: any) => {
                    options.push({
                        value: ghat.id,
                        label: ghat.ghatName
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    const fetchAllDivisions = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.manage.division.getDivisions
            )

            if (response) {
                const options: any = [];
                response.data.forEach((division: any) => {
                    options.push({
                        value: division.id,
                        label: `${division.divisionName}`
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    return {
        fetchAllDivisions,
        fetchGhatsByDivision,
        fetchAllGhats,
        fetchAllWeekDays,
        fetchAllItems,
        fetchAllDecks,
        fetchVesselTypes,
        fetchUnitTypes,
        fetchStatusList,
        fetchServiceTypes,
        fetchPwd,
        fetchOwners,
        fetchNightNavigations,
        fetchBookingModes,
        fetchModes,
        fetchGenders,
        fetchDeviceTypes,
    }

}

