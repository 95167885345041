import RouteIcon from '@mui/icons-material/Route';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { useEffect, useState } from 'react';
import Body from '../../components/body/Body';
import { useEnumService } from '../../hooks/useEnumService';
import Card from '../../components/cards/Card';
import FormControl from '../../components/forms/FormControl';
import DefaultButton from '../../components/buttons/DefaultButton';
import { handleError } from '../../services/handleError';
import { useApiService } from '../../hooks/useApiService';
import apiUrl from '../../constants/api-constants';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TableActionButton from '../../components/buttons/TableActionButton';
import ModalWrapper from '../../components/modals/ModalWrapper';
import toast from 'react-hot-toast';


const RouteManagement: React.FC = () => {
    const { fetchAuthRequest, postAuthRequest } = useApiService();
    const { 
        fetchAllDivisions,
        fetchStatusList,
        fetchGhatsByDivision,
        fetchAllGhats
    } = useEnumService();

    const [route, setRoute] = useState('');
    const [routeList, setRouteList] = useState<any[]>([]);
    const [status, setStatus] = useState('');
    const [statusOptions, setStatusOptions] = useState<any[]>([]);
    const [division, setDivision] = useState('');
    const [divisionOptions, setDivisionOptions] = useState<any[]>([]);

    const [ghatFromList, setGhatFromList] = useState<any[]>([]);
    const [ghatToList, setGhatToList] = useState<any[]>([]);

    const [routeTableRows, setRouteTableRows] = useState<any[]>([]);
    const routeTableColumns: GridColDef[] = [
        { field: 'id', headerName: 'Sl No', flex: 1 },
        { field: 'ghatFrom', headerName: 'From', flex: 2 },
        { field: 'ghatTo', headerName: 'To', flex: 2 },
        { field: 'status', headerName: 'Status', flex: 1 },
        {
            align: 'center',
            flex: 1,
            headerAlign: 'center',
            field: 'action',
            headerName: 'Action',
            sortable: false,
            filterable: false,
            renderCell: (params: any) => {
                const { id, ghatFromId, ghatToId } = params.row;
                return (
                    <div className='flex gap-2 justify-center items-center h-full'>
                        <TableActionButton
                            type='primary'
                            onClick={() => handleTableButtonClick(id, ghatFromId, ghatToId)}>
                            <BorderColorIcon fontSize='small' />
                        </TableActionButton>
                    </div>
                );
            },
        }
    ];

    const [routeModalOpen, setRouteModalOpen] = useState(false);
    const handleRouteModalOpen = () => setRouteModalOpen(true);
    const handleRouteModalClose = () => setRouteModalOpen(false);

    // ------------------------------------------------
    // ------------------- FORM -----------------------
    // ------------------------------------------------
    const [ghatFrom, setGhatFrom] = useState('');
    const [ghatFromOptions, setGhatFromOptions] = useState<any[]>([]);
    const [ghatTo, setGhatTo] = useState('');
    const [ghatToOptions, setGhatToOptions] = useState<any[]>([]);

    useEffect(() => {
        fetchDivisionOptions();
        fetchStatusOptions();
    }, []);

    useEffect(() => {
        if (division != '') {
            fetchGhatFromList();
            fetchGhatToList();
        }
    }, [division]);

    const fetchDivisionOptions = async () => {
        const options = await fetchAllDivisions();
        if (options) {
            setDivisionOptions(options);
            setDivision(options[0]?.value);
        }
    }

    const fetchStatusOptions = async () => {
        const options = await fetchStatusList();
        if (options) {
            setStatusOptions(options);
            setStatus(options[0]?.value);
        }
    }

    const fetchGhatFromList = async () => {
        const options = await fetchGhatsByDivision(division);
        if (options) {
            setGhatFromList(options);
        }
    }

    const initializeOptions = (ghatFromInitial?: any, ghatToInitial?: any) => {
        let initialGhatFrom = ghatFromInitial;
        let initialGhatTo = ghatToInitial;

        if (!ghatFromInitial && ghatFromList.length > 0) {
            initialGhatFrom = ghatFromList[0].value;
        }
        if (!ghatToInitial && ghatToList.length > 0) {
            initialGhatTo = ghatToList.find(ghat => ghat.value !== initialGhatFrom)?.value || '';
        }

        setGhatFrom(initialGhatFrom);
        setGhatTo(initialGhatTo);

        const filteredGhatFromOptions = ghatFromList.filter(option => option.value !== initialGhatTo);
        const filteredGhatToOptions = ghatToList.filter(option => option.value !== initialGhatFrom);

        setGhatFromOptions(filteredGhatFromOptions);
        setGhatToOptions(filteredGhatToOptions);
    };

    const fetchGhatToList = async () => {
        const options = await fetchAllGhats();
        if (options) {
            setGhatToList(options);
        }
    }

    const handleFetchRoutes = () => fetchRoutes();

    const fetchRoutes = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.manage.route.getRoutesByDivision,
                {
                    divisionId: division
                }
            );

            if (response) {
                const { data } = response;
                setRouteList(data);

                const rows: any = [];

                data.forEach((route: any) => {
                    rows.push({
                        id: route.id,
                        ghatFromId: route.ghatFrom.id,
                        ghatToId: route.ghatTo.id,
                        ghatFrom: route.ghatFrom.ghatName,
                        ghatTo: route.ghatTo.ghatName,
                        status: route.status
                    })
                });

                setRouteTableRows(rows);
            }

        } catch (error) {
            handleError(error);
        }
    }

    const handleAddButtonClick = async () => {
        initializeOptions();
        handleRouteModalOpen();
    }

    // -----------------------------------------
    // --------------- EDIT --------------------
    // -----------------------------------------
    const handleTableButtonClick = async (id: string, ghatFrom: string, ghatTo: string) => {
        initializeOptions(ghatFrom, ghatTo);
        handleRouteModalOpen();
    }

    const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const value = e.target.value;
        switch (e.target.name) {
            case 'division':
                setDivision(value);
                break;
            default:
                break;
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        switch (e.target.name) {
            case 'ghatFrom':
                {
                    if (value == ghatTo) {
                        toast.error("Origin and Destination cannot be same.")
                    } else {
                        setGhatFrom(value);
                        const filteredOptions = ghatToList.filter((option: any) => option.value != value);
                        setGhatToOptions(filteredOptions);
                    }
                    break;
                }
            case 'ghatTo':
                {
                    if (value == ghatFrom) {
                        toast.error("Origin and Destination cannot be same.")
                    } else {
                        setGhatTo(value);
                        const filteredOptions = ghatFromList.filter((option: any) => option.value != value);
                        setGhatFromOptions(filteredOptions);
                    }
                    break;
                }
            case 'status':
                setStatus(value);
                break;
            default:
                break;
        }
    }

    const handleRouteSaveClick = async () => {
        const postData = {
            ghatFrom: { id: ghatFrom },
            ghatTo: { id: ghatTo },
            status: status
        }
        try {
            const response: any = await postAuthRequest(
                apiUrl.manage.route.saveRoute,
                postData
            );
            if (response) {
                const { message } = response.data;
                toast.success(message);
                fetchRoutes();
                handleRouteModalClose();
            }
        } catch (error) {
            handleError(error);
        }
    }

    return (
        <Body>
            <div className='flex flex-wrap justify-between items-center'>
                <h3 className='flex justify-center items-center gap-4'><RouteIcon /> Route Management</h3>
            </div>
            <Card>
                <div className='flex w-full items-end justify-between gap-2'>
                    <div className='flex items-end gap-2'>
                        <div>
                            <FormControl
                                label='Select Division'
                                type={'select'}
                                id={'division'}
                                value={division}
                                onChange={handleFilterInputChange}
                                options={divisionOptions}
                            />
                        </div>
                        <div className='mb-1'>
                            <DefaultButton
                                buttonText='Fetch Routes'
                                onClick={handleFetchRoutes}
                                disabledTime={1}
                            />
                        </div>
                    </div>
                    <div className='flex w-max mt-4 mb-2'>
                        <DefaultButton
                            onClick={handleAddButtonClick}
                            buttonText='Add New Route'
                            disabledTime={0}
                            buttonIcon={AddRoundedIcon}
                        />
                    </div>
                </div>
            </Card>
            <div className='mt-3'>
                <div className='flex justify-center'>
                    <div className='flex w-full bg-white'>
                        <DataGrid
                            rows={routeTableRows}
                            columns={routeTableColumns}
                            autosizeOnMount
                            autoHeight
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15]}
                        />
                    </div>
                </div>
            </div>

            <ModalWrapper
                open={routeModalOpen}
                handleClose={handleRouteModalClose}
                title={'Route Details'}>
                <form id='routeForm' className='flex flex-wrap'>
                    <div className='w-full lg:w-2/5 lg:pr-4'>
                        <input type='text' id='id' name='id' value={route} readOnly hidden />
                        <FormControl
                            label='From'
                            type={'select'}
                            id={'ghatFrom'}
                            value={ghatFrom}
                            options={ghatFromOptions}
                            onChange={handleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-2/5 lg:pr-4'>
                        <FormControl
                            label='To'
                            type={'select'}
                            id={'ghatTo'}
                            value={ghatTo}
                            options={ghatToOptions}
                            onChange={handleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/5'>
                        <FormControl
                            label='Status'
                            type={'select'}
                            id={'status'}
                            value={status}
                            options={statusOptions}
                            onChange={handleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                </form>
                <div className='mt-4'>
                    <DefaultButton
                        onClick={handleRouteSaveClick}
                        buttonText={'Save Route Details'}
                    />
                </div>
            </ModalWrapper>
        </Body>
    )
}

export default RouteManagement