import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CurrencyRupeeRoundedIcon from '@mui/icons-material/CurrencyRupeeRounded';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Body from '../../components/body/Body';
import DefaultButton from '../../components/buttons/DefaultButton';
import Card from '../../components/cards/Card';
import FormControl from '../../components/forms/FormControl';
import ModalWrapper from '../../components/modals/ModalWrapper';
import apiUrl from '../../constants/api-constants';
import { useApiService } from '../../hooks/useApiService';
import { handleError } from '../../services/handleError';
import { useEnumService } from '../../hooks/useEnumService';

const RateManagement: React.FC = () => {
    const { fetchAuthRequest, postAuthRequest } = useApiService();
    const {
        fetchAllDivisions,
        fetchVesselTypes,
        fetchServiceTypes,
        fetchAllItems
    } = useEnumService();
    const [rateList, setRateList] = useState<any[]>([]);
    const [itemRateList, setItemRateList] = useState<any[]>([]);

    const [itemList, setItemList] = useState<any[]>([]);

    const [division, setDivision] = useState('');
    const [divisionOptions, setDivisionOptions] = useState<any[]>([]);

    const [routes, setRoutes] = useState<any[]>([]);
    const [serviceTypeOptions, setServiceTypeOptions] = useState<any[]>([]);
    const [vesselTypeOptions, setVesselTypeOptions] = useState<any[]>([]);

    const [ghatFrom, setGhatFrom] = useState('');
    const [ghatFromOptions, setGhatFromOptions] = useState<any[]>([]);

    const [ghatTo, setGhatTo] = useState('');
    const [ghatToOptions, setGhatToOptions] = useState<any[]>([]);

    const [rateModalOpen, setRateModalOpen] = useState(false);
    const handleRateModalOpen = () => setRateModalOpen(true);
    const handleRateModalClose = () => setRateModalOpen(false);

    const [rateTableRows, setRateTableRows] = useState<any[]>([]);
    const rateTableCols: GridColDef[] = [
        { field: 'slNo', headerName: 'Sl No' },
        { field: 'vesselType', headerName: 'Vessel Type', flex: 2 },
        { field: 'serviceType', headerName: 'Service Type', flex: 2 },
        { field: 'itemLabel', headerName: 'Item', flex: 2 },
        { field: 'rate', headerName: 'Rate', flex: 2 },
    ];

    // -------------------------------------------------
    // ------------------ FORM -------------------------
    // -------------------------------------------------
    const [formGhatFrom, setFormGhatFrom] = useState('');
    const [formGhatFromOptions, setFormGhatFromOptions] = useState<any[]>([]);
    const [formGhatTo, setFormGhatTo] = useState('');
    const [formGhatToOptions, setFormGhatToOptions] = useState<any[]>([]);
    const [vesselType, setVesselType] = useState('');
    const [serviceType, setServiceType] = useState('');

    useEffect(() => {
        fetchDivisionOptions();
        fetchVesselTypeOptions();
        fetchServiceTypeOptions();
        fetchItemList()
    }, []);

    useEffect(() => {
        if (division != '') {
            fetchRoutesByDivision();
        }
    }, [division]);

    useEffect(() => {
        if (routes.length > 0) {
            generateGhatFromOptions();
        }
    }, [routes]);

    useEffect(() => {
        if (ghatFrom != '') {
            generateGhatToOptions();
        }
    }, [ghatFrom]);

    useEffect(() => {
        if (formGhatFrom != '') {
            generateFormGhatToOptions();
        }
    }, [formGhatFrom]);

    useEffect(() => {
        filterRates();
    }, [formGhatFrom, formGhatTo, vesselType, serviceType, routes, rateList]);

    const fetchDivisionOptions = async () => {
        const options = await fetchAllDivisions();
        if (options) {
            setDivisionOptions(options);
            setDivision(options[0]?.value);
        }
    }

    const fetchVesselTypeOptions = async () => {
        const options: any = await fetchVesselTypes();
        if (options) {
            setVesselTypeOptions(options);
            setVesselType(options[0]?.value);
        }
    }

    const fetchServiceTypeOptions = async () => {
        const options: any = await fetchServiceTypes();
        if (options) {
            setServiceTypeOptions(options);
            setServiceType(options[0]?.value);
        }
    }

    const fetchItemList = async () => {
        const response: any = await fetchAllItems();
        if (response) {
            const { data } = response;
            const options: any = [];
            data.forEach((item: any) => {
                options.push({
                    value: item.id,
                    label: `${item.itemType}(${item.unitType})`
                })
            });
            setItemList(options);
        }
    }

    const generateGhatFromOptions = () => {
        const ghats: any = [];
        routes.forEach((route: any) => {
            if (!ghats.find((ghat: any) => ghat.value == route.ghatFrom.id)) {
                ghats.push({
                    value: route.ghatFrom.id,
                    label: route.ghatFrom.ghatName
                })
            }
        });
        setGhatFromOptions(ghats);
        setGhatFrom(ghats[0]?.value);
    }

    const generateGhatToOptions = () => {
        const ghats: any = [];
        routes.forEach((route: any) => {
            if (!ghats.find((ghat: any) => ghat.value == route.ghatTo.id)) {
                if (route.ghatFrom.id == ghatFrom) {
                    ghats.push({
                        value: route.ghatTo.id,
                        label: route.ghatTo.ghatName
                    })
                }
            }
        });
        setGhatToOptions(ghats);
        setGhatTo(ghats[0]?.value);
    }

    const generateFormGhatFromOptions = () => {
        const ghats: any = [];
        routes.forEach((route: any) => {
            if (!ghats.find((ghat: any) => ghat.value == route.ghatFrom.id)) {
                ghats.push({
                    value: route.ghatFrom.id,
                    label: route.ghatFrom.ghatName
                })
            }
        });
        setFormGhatFromOptions(ghats);
        setFormGhatFrom(ghats[0]?.value);
    }

    const generateFormGhatToOptions = () => {
        const ghats: any = [];
        routes.forEach((route: any) => {
            if (!ghats.find((ghat: any) => ghat.value == route.ghatTo.id)) {
                if (route.ghatFrom.id == formGhatFrom) {
                    ghats.push({
                        value: route.ghatTo.id,
                        label: route.ghatTo.ghatName
                    })
                }
            }
        });
        setFormGhatToOptions(ghats);
        setFormGhatTo(ghats[0]?.value);
    }

    const generateItemInputs = () => {
        const elements: any = [];
        if (itemList) {
            itemList.map((item, index) => {
                const existingItem = itemRateList.find((rateItem: any) => item.value == rateItem.item.id);
                elements.push(
                    <div key={index} className='w-1/4 pr-4'>
                        <FormControl
                            label={item.label}
                            type={'number'}
                            id={'item_' + item.value}
                            placeholder='Capacity'
                            value={existingItem ? existingItem.rate : 0}
                            onChange={handleRateInputChange}
                            mandatoryField={true}
                        />
                    </div>
                );
            })
        }
        return elements;
    }

    const fetchRoutesByDivision = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.manage.route.getRoutesByDivision,
                {
                    divisionId: division
                }
            );

            if (response) {
                const { data } = response;
                setRoutes(data);
                if (data.length == 0) {
                    setGhatFromOptions([]);
                    setGhatToOptions([]);
                } else {
                    generateGhatFromOptions();
                }
            }
        } catch (error) {
            handleError(error);
        }
    }

    const handleAddRate = async () => {
        generateFormGhatFromOptions();
        setFormGhatFrom(ghatFrom);
        setFormGhatTo(ghatTo);
        setVesselType(vesselTypeOptions[0]?.value);
        setServiceType(serviceTypeOptions[0]?.value);
        filterRates();
        handleRateModalOpen();
    }

    const handleFetchRates = async () => fetchRatesByRoute();

    const fetchRatesByRoute = async () => {
        try {
            const route = routes.find((route: any) => route.ghatFrom.id == ghatFrom && route.ghatTo.id == ghatTo);

            const response: any = await fetchAuthRequest(
                apiUrl.manage.rate.getRatesByRoute,
                {
                    routeId: route.id
                }
            );

            if (response) {
                const { data } = response;
                const rows: any[] = [];
                let count = 1;
                data.forEach((rate: any) => {
                    rate.itemRates.forEach((itemRate: any) => {
                        rows.push({
                            slNo: count,
                            id: count,
                            rateId: rate.id,
                            vesselType: rate.vesselType,
                            serviceType: rate.serviceType,
                            itemType: itemRate.item.itemType,
                            itemUnitType: itemRate.item.unitType,
                            itemLabel: `${itemRate.item.itemType} (${itemRate.item.unitType})`,
                            rate: itemRate.rate
                        });

                        count++;
                    })
                });
                setRateTableRows(rows);
                setRateList(data);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const value = e.target.value;
        switch (e.target.name) {
            case 'division':
                setDivision(value);
                break;
            case 'ghatFrom':
                setGhatFrom(value);
                break;
            case 'ghatTo':
                setGhatTo(value);
                break;
            default:
                break;
        }
    }

    const filterRates = () => {
        const selectedRoute = routes.find(route => route.ghatFrom.id == formGhatFrom && route.ghatTo.id == formGhatTo);
        if (!selectedRoute) {
            setItemRateList([]);
            return;
        }
        const rate = rateList.find((rate: any) => rate.route.id == selectedRoute.id && rate.vesselType == vesselType && rate.serviceType == serviceType);
        if (!rate) {
            setItemRateList([]);
            return;
        }
        setItemRateList(rate.itemRates);
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const value = e.target.value;
        switch (e.target.name) {
            case 'formGhatFrom':
                setFormGhatFrom(value);
                break;
            case 'formGhatTo':
                setFormGhatTo(value);
                break;
            case 'vesselType':
                setVesselType(value);
                break;
            case 'serviceType':
                setServiceType(value);
                break;
            default:
                break;
        }
    }

    const handleRateInputChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const [_, itemId] = e.target.name.split('_');
        const rateValue = parseInt(e.target.value);

        // Find the existing item or create a new one if it doesn't exist
        const existingItemIndex = itemRateList.findIndex(rateItem => rateItem.item.id == Number(itemId));
        const newItem = {
            // id: Math.max(...deckItems.map(item => item.id)) + 1,
            item: { id: Number(itemId) },
            rate: rateValue
        };

        if (existingItemIndex >= 0) {
            // Update the existing item
            const updatedRateList = [...itemRateList];
            updatedRateList[existingItemIndex] = newItem;
            setItemRateList(updatedRateList);
        } else {
            // Add a new item
            setItemRateList([...itemRateList, newItem]);
        }
    }

    const handleSaveRateClick = async () => {
        const selectedRoute = routes.find(route => route.ghatFrom.id == formGhatFrom && route.ghatTo.id == formGhatTo);
        const postData = {
            route: {
                id: selectedRoute?.id
            },
            vesselType: vesselType,
            serviceType: serviceType,
            itemRates: itemRateList,
        }

        try {
            const response: any = await postAuthRequest(
                apiUrl.manage.rate.saveRate,
                postData
            );
            if (response) {
                const { message } = response.data;
                toast.success(message);
            }
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <Body>
            <div className='flex flex-wrap justify-between items-end'>
                <h3 className='flex justify-center items-center gap-4 mb-2'><CurrencyRupeeRoundedIcon /> Rate Management</h3>
            </div>
            <Card >
                <div className='flex gap-2 items-end'>
                    <div className='w-full lg:w-1/5'>
                        <FormControl
                            label='Division'
                            type={'select'}
                            id={'division'}
                            value={division}
                            options={divisionOptions}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <div className='w-full lg:w-1/5'>
                        <FormControl
                            label='From'
                            type={'select'}
                            id={'ghatFrom'}
                            value={ghatFrom}
                            options={ghatFromOptions}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <div className='w-full lg:w-1/5'>
                        <FormControl
                            label='To'
                            type={'select'}
                            id={'ghatTo'}
                            value={ghatTo}
                            options={ghatToOptions}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <div className='w-full lg:w-1/5 pb-1'>
                        <DefaultButton
                            onClick={handleFetchRates}
                            buttonText={'Fetch Rates'}
                            disabledTime={1}
                        />
                    </div>
                    <div className='w-full lg:w-1/5 pb-1'>
                        <DefaultButton
                            onClick={handleAddRate}
                            buttonText={'Update Rates'}
                            buttonIcon={AddOutlinedIcon}
                            disabledTime={1}
                        />
                    </div>
                </div>
            </Card>
            <div className='mt-3'>
                <div className='flex justify-center'>
                    <div className='flex w-full bg-white'>
                        <DataGrid
                            rows={rateTableRows}
                            columns={rateTableCols}
                            autosizeOnMount
                            autoHeight
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15]}
                        />
                    </div>
                </div>
            </div>

            <ModalWrapper
                title={'Rate Details'}
                open={rateModalOpen}
                handleClose={handleRateModalClose}
            >
                <div className='flex flex-wrap'>
                    <div className='w-full lg:w-1/3 lg:pr-4'>
                        <FormControl
                            label='From'
                            type={'select'}
                            id={'formGhatFrom'}
                            value={formGhatFrom}
                            options={formGhatFromOptions}
                            onChange={handleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3 lg:pr-4'>
                        <FormControl
                            label='To'
                            type={'select'}
                            id={'formGhatTo'}
                            value={formGhatTo}
                            options={formGhatToOptions}
                            onChange={handleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3'>
                        <FormControl
                            label='Vessel Type'
                            type={'select'}
                            id={'vesselType'}
                            value={vesselType}
                            options={vesselTypeOptions}
                            onChange={handleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3 lg:pr-4'>
                        <FormControl
                            label='Service Type'
                            type={'select'}
                            id={'serviceType'}
                            value={serviceType}
                            options={serviceTypeOptions}
                            onChange={handleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full'>
                        <h6 className='text-blue-400'>Item-wise Rates (&#8377;)</h6>
                    </div>
                    {generateItemInputs()}
                    <div className='w-full mt-4'>
                        <DefaultButton
                            onClick={handleSaveRateClick}
                            buttonText='Save Rate Details'
                        />
                    </div>
                </div>
            </ModalWrapper>
        </Body>
    )
}

export default RateManagement