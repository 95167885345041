import React, { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Collapse } from '@mui/material';
import DummyQr from '../../resources/images/dummy_qr.png';

interface TicketProps {
    id: string
}

const Ticket: React.FC<TicketProps> = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='w-full bg-white rounded-xl shadow roboto-mono'>
            <div className='md:flex md:h-full pt-4 md:pt-0'>
                <div className='bg-sky-600 text-center md:flex md:w-max md:h- items-center md:rounded-l-lg md:rounded-tr-none'>
                    <div className='text-white'>
                        <div className='py-2 md:py-0 md:-rotate-90'>
                            TICKET
                        </div>
                    </div>
                </div>
                <div className='w-full'>
                    <div className='w-full px-8 py-4 flex items-center cursor-pointer' onClick={toggleAccordion}>
                        <div className='w-11/12'>
                            <h3 className='text-sky-600'>Jalpori I</h3>
                            <div className='w-full flex flex-wrap'>
                                <div className='w-full md:w-1/2'>
                                    <p>Trip: North Guwahati → Umananda</p>
                                    <p>Vessel No: AS 01 AJ 8523</p>
                                </div>
                                <div className='w-full md:w-1/2 md:pl-4'>
                                    <p>Date: 24-06-2024, Mon</p>
                                    <p>Departure Time: 02:30 PM</p>
                                </div>
                            </div>
                            <p className="text-neutral-400">Booked On: 21-04-2024</p>
                        </div>
                        <div className='hidden w-1/12 md:flex justify-end h-full'>
                            <ArrowForwardIosIcon
                                className={`transform transition-transform duration-300 ${isOpen ? 'rotate-90' : 'rotate-0'
                                    }`} />
                        </div>
                    </div>
                    <div className='flex md:hidden w-full justify-center h-full pb-2'>
                        <ArrowForwardIosIcon
                            className={`transform transition-transform duration-300 
                                ${isOpen ? '-rotate-90' : 'rotate-90'}`} />
                    </div>
                    <Collapse in={isOpen}>
                        <div className="px-8 py-4">
                            <div className='md:w-11/12 grid grid-cols-1 md:grid-cols-2 gap-x-8'>
                                <div className="border-t border-red-100 mb-4 pt-3">
                                    <div className="text-lg font-semibold">Passenger:</div>
                                    <div className="flex items-center justify-between pr-4 md:pr-8">
                                        <div>
                                            <div>Mrinmoy Dutta (M)</div>
                                            <div>Age: 28</div>
                                        </div>
                                        <div className='w-20'>
                                            <img
                                                src={DummyQr}
                                                alt="QR Code"
                                                className="ml-4"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="border-t border-red-100 mb-4 pt-3">
                                    <div className="text-lg font-semibold">Passenger:</div>
                                    <div className="flex items-center justify-between pr-4 md:pr-8">
                                        <div>
                                            <div>Koustav Bordoloi (M)</div>
                                            <div>Age: 28</div>
                                        </div>
                                        <div className='w-20'>
                                            <img
                                                src={DummyQr}
                                                alt="QR Code"
                                                className="ml-4"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="border-t border-red-100 mb-4 pt-3">
                                    <div className="text-lg font-semibold">Vehicle:</div>
                                    <div className="flex items-center justify-between pr-4 md:pr-8">
                                        <div>
                                            <div>YAMAHA FZS-FI</div>
                                            <div>Reg No: AS 02 AJ 7203</div>
                                        </div>
                                        <div className='w-20'>
                                            <img
                                                src={DummyQr}
                                                alt="QR Code"
                                                className="ml-4"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="md:w-11/12 flex justify-between items-center mt-4 mb-2 text-red-500">
                                <h5 className="text-destructive">Amount: ₹15.45</h5>
                                <h5 className="text-destructive hover:underline">Download Ticket</h5>
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>

        </div>
    )
}

export default Ticket