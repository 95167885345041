import { TicketIcon } from "@heroicons/react/24/outline";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Card from "../../components/cards/Card";
import ContentClickable from "../../components/clickables/ContentClickable";
import DefaultFooter from "../../components/footers/DefaultFooter";
import SearchInput from "../../components/forms/SearchInput";
import DefaultHeader from "../../components/headers/DefaultHeader";
import ModalWrapper from "../../components/modals/ModalWrapper";
import apiUrl from "../../constants/api-constants";
import { ROUTES } from "../../constants/routes-constants";
import { useApiService } from "../../hooks/useApiService";
import { dateUtil } from "../../utility/dateUtil";
import { handleError } from "../../services/handleError";

const UserLanding: React.FC = () => {
    const { fetchPublicRequest } = useApiService();
    const navigate = useNavigate();
    const [origin, setOrigin] = useState('');
    const [originName, setOriginName] = useState('Origin');
    const [destination, setDestination] = useState('');
    const [destinationName, setDestinationName] = useState('Destination');
    const [searchText, setSearchText] = useState('');
    const [tripDate, setTripDate] = useState(dateUtil.formatDate((new Date()).toString()));

    const [routes, setRoutes] = useState<any[]>([]);
    const [ghatList, setGhatList] = useState<any[]>([]);

    const [ghatOptionModalOpen, setGhatOptionModalOpen] = useState(false);
    const handleGhatOptionModalOpen = () => setGhatOptionModalOpen(true);
    const handleGhatOptionModalClose = () => setGhatOptionModalOpen(false);

    const searchInputRef = useRef<HTMLInputElement>(null);

    const [selectedParameter, setSelectedParameter] = useState('');

    useEffect(() => {
        fetchRoutes();
    }, []);

    const fetchRoutes = async () => {
        try {
            const response: any = await fetchPublicRequest(apiUrl.guest.getRoutes);
            if (response) {
                const { data } = response;
                setRoutes(data);
            }

        } catch (error) {
            handleError(error);
        }
    }

    const routesToGhatList = (clickedDiv: any) => {
        const divisionsMap: any = {};

        routes.forEach((route) => {
            let ghats: any = [];

            if (clickedDiv == 'destination') {
                if (route.ghatFrom.id == origin) {
                    ghats = [route.ghatTo];
                }
            } else {
                ghats = [route.ghatFrom, route.ghatTo];
            }

            ghats.forEach((ghat: any) => {
                const divisionId = ghat.division.id;
                const divisionName = ghat.division.divisionName;
                if (!divisionsMap[divisionId]) {
                    // Initialize the division if it does not exist
                    divisionsMap[divisionId] = {
                        divisionId: divisionId.toString(),
                        divisionName: divisionName,
                        ghats: []
                    };
                }
                if (!divisionsMap[divisionId].ghats.find((g: any) => g.id === ghat.id.toString())) {
                    // Add the ghat to the division if it is not already included
                    divisionsMap[divisionId].ghats.push({
                        id: ghat.id.toString(),
                        name: ghat.ghatName
                    });
                }
            });
        });

        // Convert the object to an array format
        return Object.values(divisionsMap);
    };

    const handleSwapClick = () => {
        if (origin.trim() == '') {
            toast.error("Please select origin");
            return;
        } else {
            if (destination.trim() == '') {
                toast.error("Please select destination");
                return;
            }
            const newOrigin = destination;
            const newOriginName = destinationName;

            setOrigin(newOrigin);
            setOriginName(newOriginName);
        }

        if (destination.trim() == '') {
            toast.error("Please select destination")
        } else {
            const newDestination = origin;
            const newDestinationName = originName;

            setDestination(newDestination);
            setDestinationName(newDestinationName);
        }
    }

    const getRouteFromOriginAndDestination = (origin: string, destination: string) => {
        const match = routes.find((route) => route.ghatFrom.id == origin && route.ghatTo.id == destination);
        if (match) {
            return match.id;
        }
        return null;
    }

    const handleSearchClick = async () => {
        if (origin.trim() == '' || destination.trim() == '') {
            if (origin.trim() == '' && destination.trim() == '') {
                toast.error("Please select origin.");
            } else {
                origin.trim() == '' && toast.error("Please select origin.");
                destination.trim() == '' && toast.error("Please select destination.");
            }
        } else {
            const routeId: any = getRouteFromOriginAndDestination(origin, destination);

            if (routeId && tripDate.trim() != '') {
                const response: any = await fetchPublicRequest(
                    apiUrl.guest.getTrips,
                    {
                        routeId: routeId,
                        tripDate: tripDate
                    }
                );

                if (response) {
                    const { data } = response;

                    if (data.length > 0) {
                        localStorage.setItem('searchResults', JSON.stringify(data));
                        navigate(ROUTES.USER.SEARCH_RESULTS);
                    } else {
                        toast.error("No trips found for the selected route and date.");
                    }
                } else {
                    toast.error("Failed to fetch trips. Please try again.");
                }
            }
        }
    }

    const showSearchModal = (clickedDiv: string) => {
        setSelectedParameter(clickedDiv);
        const ghats = routesToGhatList(clickedDiv);
        setGhatList(ghats);
        handleGhatOptionModalOpen();
        searchInputRef.current?.focus();
    }

    const handleSearchOptionSelect = (ghatId: any, ghatName: string, parent: string) => {
        switch (parent) {
            case 'origin':
                if (ghatId !== origin) {
                    setOrigin(ghatId);
                    setOriginName(ghatName);
                    // Reseting the destination only when a new destination is selected
                    setDestination('');
                    setDestinationName('Destination');
                }
                break;
            case 'destination':
                if (ghatId !== destination) {
                    setDestination(ghatId);
                    setDestinationName(ghatName);
                }
                break;
            default:
                break;
        }

        handleGhatOptionModalClose();
        setSelectedParameter('');
        setSearchText('');
    }

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const matchesSearchText = (ghatName: string) => {
        if (searchText.trim() !== '') {
            return ghatName.toLowerCase().includes(searchText.toLowerCase());
        }

        return true;
    }

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTripDate(e.target.value);
    }

    return (
        <div className="bg-slate-100 flex flex-col justify-between min-h-screen">
            <div>
                <DefaultHeader />
                <div style={{
                    backgroundImage: `url(${require("../../resources/images/dashboard_bg_2.jpg")})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }} className="h-full flex items-center justify-center">
                    <div className="container mt-3 max-w-7xl mx-auto px-4 sm:px-6">
                        <div className="p-4">
                            <div className="flex justify-center">
                                <img className="w-40" src={require("../../resources/images/aiwtd_logo.png")} alt="" />
                            </div>
                            <div className="text-3xl text-center mb-4 text-sky-700 mt-2">
                                Search Vessels
                            </div>

                            <div className="flex flex-wrap justify-center">
                                <div className="rounded-xl w-full md:w-3/4 lg:w-2/3 outline outline-2 outline-blue-400 flex flex-wrap bg-slate-50">
                                    <div className="w-full lg:w-7/12 flex flex-wrap">
                                        <div className="w-full lg:w-1/2 px-6 bg-transparent">
                                            <div
                                                className="w-full md:text-center text-nowrap text-clip overflow-x-hidden py-3.5"
                                                onClick={() => showSearchModal('origin')}>
                                                {originName}
                                            </div>
                                        </div>
                                        <div className="relative bg-slate-400 flex items-center">
                                            <button className="absolute -left-12 top-7 lg:-left-5 lg:top-1.5 flex p-2 bg-zinc-500 text-white rounded-full scale-90 rotate-90 lg:rotate-0"
                                                onClick={handleSwapClick}
                                            >{<SwapHorizRoundedIcon />}</button>
                                        </div>
                                        <div className="w-full lg:w-1/2 px-6 bg-transparent">
                                            <div
                                                className="w-full md:text-center text-nowrap text-clip overflow-x-hidden py-3.5"
                                                onClick={() => showSearchModal('destination')}>
                                                {destinationName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-5/12 flex flex-wrap">
                                        <div className="w-full lg:w-7/12 px-6 bg-transparent">
                                            <input
                                                className="w-full md:text-center text-nowrap text-clip overflow-x-hidden py-3.5 bg-transparent"
                                                type="date"
                                                value={tripDate}
                                                onChange={handleDateChange}
                                                min={dateUtil.today()}
                                            />
                                        </div>
                                        <div className="w-full lg:w-5/12">
                                            <button
                                                className="w-full justify-center h-full p-3.5 bg-sky-600 text-white font-medium rounded-b-xl lg:rounded-bl-none lg:rounded-r-xl flex gap-2 pl-5 items-center outline outline-1 outline-sky-600"
                                                onClick={handleSearchClick}>
                                                Search <SearchRoundedIcon />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-4 mb-4 text-slate-700 font-medium">
                                Assam Inland Water Transport Development Society
                            </div>
                            <div className="w-full md:w-2/3 lg:w-1/2 mx-auto">
                                <hr className="border-slate-300 border-1" />
                            </div>
                            <div className="text-center mt-4 mb-4 text-slate-700 w-full lg:w-1/2 flex mx-auto text-sm">
                                Unlock exclusive discounts with our Bulk Booking feature. Purchase tickets in bulk for your favorite routes and sail hassle-free with every trip
                            </div>
                            <div className="flex justify-center p-2 gap-4">
                                <ContentClickable
                                    href={ROUTES.USER.BUNDLE_TICKET_BOOK}
                                    icon={<TicketIcon />}
                                    header="Route Ticket Bundle"
                                    text="Unlock Savings on Your Frequent Routes"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* OTHER CONTENTS INSIDE THE DIV BELOW */}
                {/* <div className="container mt-3 max-w-7xl mx-auto px-4 sm:px-6">
                    <Card addClass={["mt-4"]}>
                        <div>
                            <div className="text-lg font-semibold">
                                Recent Bookings
                            </div>
                            <div>
                                This section is under construction. Check again later.

                                <button onClick={() => navigate(ROUTES.USER.MY_BOOKINGS)}> My Bookings</button>
                            </div>
                        </div>
                    </Card>
                </div> */}
            </div>
            <DefaultFooter />

            <ModalWrapper
                open={ghatOptionModalOpen}
                handleClose={handleGhatOptionModalClose}
                title={"Select Ghat"}
                width="small"
            >
                <div className="flex flex-col items-center justify-center">
                    <SearchInput
                        id={"ghatInput"}
                        onChange={handleSearchInputChange}
                        value={searchText}
                        inputRef={searchInputRef}
                    />
                    <div className="mt-2 w-full lg:h-80 lg:overflow-y-scroll">
                        {
                            ghatList.map((division => (
                                <div key={division.divisionId}>
                                    <div className="my-1 w-full px-4 py-1 text-sm bg-slate-200">
                                        {division.divisionName}
                                    </div>
                                    {division.ghats.map(((ghat: any) => (
                                        (matchesSearchText(ghat.name) &&
                                            <div key={ghat.id}
                                                className="bg-slate-100 border border-1 border-slate-200 my-1 mx-2 px-4 py-2 lg:py-1 text-sm hover:cursor-pointer"
                                                onClick={() => handleSearchOptionSelect(`${ghat.id}`, `${ghat.name}`, selectedParameter)}
                                            >
                                                {ghat.name}
                                            </div>)
                                    )))}
                                </div>
                            )))
                        }
                    </div>
                </div>
            </ModalWrapper>
        </div>
    );
}

export default UserLanding;