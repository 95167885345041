import React, { useEffect, useState } from 'react';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import Body from '../../../components/body/Body';
import Card from '../../../components/cards/Card';
import FormControl from '../../../components/forms/FormControl';
import DefaultButton from '../../../components/buttons/DefaultButton';
import { useEnumService } from '../../../hooks/useEnumService';
import { handleError } from '../../../services/handleError';
import { useApiService } from '../../../hooks/useApiService';
import apiUrl from '../../../constants/api-constants';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import PopoverDetails from '../../../components/popovers/PopoverDetails';
import TableActionButton from '../../../components/buttons/TableActionButton';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ModalWrapper from '../../../components/modals/ModalWrapper';
import toast from 'react-hot-toast';
import TripTable from './TripTable';
import { SelectOption } from '../../../constants/type-constants';
import { useTripSettingsService } from './useTripSettingService';

const TripSettings: React.FC = () => {
    const { fetchAuthRequest, postAuthRequest } = useApiService();
    // const {
    //     fetchAllDivisions,
    //     fetchStatusList,
    //     fetchBookingModes,
    //     fetchServiceTypes
    // } = useEnumService();

    const {
        fetchStatusOptions,
        fetchDivisionOptions,
        fetchRoutesByDivision,
        fetchBookingModeOptions,
        fetchServiceTypeOptions,
        fetchVesselsByDivision,
        fetchTripSettings,
        saveTrip
    } = useTripSettingsService()

    const [trip, setTrip] = useState('');
    const [tripList, setTripList] = useState<any[]>([]);
    const [division, setDivision] = useState('');
    const [routes, setRoutes] = useState<any[]>([]);
    const [ghatFrom, setGhatFrom] = useState('');
    const [ghatTo, setGhatTo] = useState('');

    const [divisionOptions, setDivisionOptions] = useState<SelectOption[]>([]);
    const [ghatFromOptions, setGhatFromOptions] = useState<SelectOption[]>([]);
    const [ghatToOptions, setGhatToOptions] = useState<SelectOption[]>([]);
    const [statusOptions, setStatusOptions] = useState<SelectOption[]>([]);
    const [bookingModeOptions, setBookingModeOptions] = useState<SelectOption[]>([]);
    const [serviceTypeOptions, setServiceTypeOptions] = useState<SelectOption[]>([]);
    const [vesselOptions, setVesselOptions] = useState<any[]>([]);


    const [tripSettingsTableRows, setTripSettingsTableRows] = useState<any[]>([]);


    // ------------------------------------------------
    // ------------------- FORM -----------------------
    // ------------------------------------------------
    const [formGhatFrom, setFormGhatFrom] = useState('');
    const [formGhatFromOptions, setFormGhatFromOptions] = useState<any[]>([]);
    const [formGhatTo, setFormGhatTo] = useState('');
    const [formGhatToOptions, setFormGhatToOptions] = useState<any[]>([]);
    const [serviceType, setServiceType] = useState('');
    const [vessel, setVessel] = useState('');
    const [departureHour, setDepartureHour] = useState('');
    const [departureMin, setDepartureMin] = useState('');
    const [departureSec, setDepartureSec] = useState('');
    const [tripDurationHour, setTripDurationHour] = useState('');
    const [tripDurationMin, setTripDurationMin] = useState('');
    const [tripDurationSec, setTripDurationSec] = useState('');
    const [tripStatus, setTripStatus] = useState('');
    const [dockNo, setDockNo] = useState('');
    const [bookingMode, setBookingMode] = useState('');
    const [activateBefore, setActivateBefore] = useState('');
    const [onlineSeat, setOnlineSeat] = useState('');
    const [offlineSeat, setOfflineSeat] = useState('');
    // ------------------------------------------------
    const [tripModalOpen, setTripModalOpen] = useState(false);
    const handleTripModalOpen = () => setTripModalOpen(true);
    const handleTripModalClose = () => setTripModalOpen(false);

    useEffect(() => {
        fetchStatusOptions(setStatusOptions, setTripStatus)
        fetchBookingModeOptions(setBookingModeOptions, setBookingMode);
        fetchDivisionOptions(setDivisionOptions, setDivision);
        fetchServiceTypeOptions(setServiceTypeOptions, setServiceType);
    }, []);

    useEffect(() => {
        if (division != '') {
            fetchVesselsByDivision(division, setVesselOptions);
            fetchRoutesByDivision(division, setGhatFromOptions, setGhatToOptions, setRoutes);
        }
    }, [division]);

    useEffect(() => {
        if (routes.length > 0) {
            generateGhatFromOptions();
        }
    }, [routes]);

    useEffect(() => {
        if (ghatFrom != '') {
            generateGhatToOptions();
        }
    }, [ghatFrom]);

    useEffect(() => {
        if (formGhatFrom != '') {
            generateFormGhatToOptions();
        }
    }, [formGhatFrom]);


    const generateGhatFromOptions = () => {
        const ghats: any = [];
        routes.forEach((route: any) => {
            if (!ghats.find((ghat: any) => ghat.value == route.ghatFrom.id)) {
                ghats.push({
                    value: route.ghatFrom.id,
                    label: route.ghatFrom.ghatName
                })
            }
        });
        setGhatFromOptions(ghats);
        setGhatFrom(ghats[0]?.value);
    }

    const generateGhatToOptions = () => {
        const ghats: any = [];
        routes.forEach((route: any) => {
            if (!ghats.find((ghat: any) => ghat.value == route.ghatTo.id)) {
                if (route.ghatFrom.id == ghatFrom) {
                    ghats.push({
                        value: route.ghatTo.id,
                        label: route.ghatTo.ghatName
                    })
                }
            }
        });
        setGhatToOptions(ghats);
        setGhatTo(ghats[0]?.value);
    }

    const generateFormGhatFromOptions = () => {
        const ghats: any = [];
        routes.forEach((route: any) => {
            if (!ghats.find((ghat: any) => ghat.value == route.ghatFrom.id)) {
                ghats.push({
                    value: route.ghatFrom.id,
                    label: route.ghatFrom.ghatName
                })
            }
        });
        setFormGhatFromOptions(ghats);
        setFormGhatFrom(ghats[0]?.value);
    }

    const generateFormGhatToOptions = () => {
        const ghats: any = [];
        routes.forEach((route: any) => {
            if (!ghats.find((ghat: any) => ghat.value == route.ghatTo.id)) {
                if (route.ghatFrom.id == formGhatFrom) {
                    ghats.push({
                        value: route.ghatTo.id,
                        label: route.ghatTo.ghatName
                    })
                }
            }
        });
        setFormGhatToOptions(ghats);
        setFormGhatTo(ghats[0]?.value);
    }


    const handleFetchSettings = async () => fetchTripSettings(
        routes,
        ghatFrom,
        ghatTo,
        setTripSettingsTableRows,
        setTripList
    );

    const handleSettingsEditClick = (id: string) => {
        generateFormGhatFromOptions();
        const tripData = tripList.find((trip: any) => trip.id == id);
        if (tripData) {
            const [depH, depM, depS] = tripData.departureTime.split(':');
            const [durH, durM, durS] = tripData.tripDuration.split(':');
            setTrip(id);
            setFormGhatFrom(tripData.route.ghatFrom.id);
            setFormGhatTo(tripData.route.ghatTo.id);
            setServiceType(tripData.serviceType || serviceType);
            setVessel(tripData.vessel.id);
            setDepartureHour(depH);
            setDepartureMin(depM);
            setDepartureSec(depS);
            setTripDurationHour(durH);
            setTripDurationMin(durM);
            setTripDurationSec(durS);
            setTripStatus(tripData.status);
            setDockNo(tripData.dockNo);
            setBookingMode(tripData.bookingMode || bookingMode);
            setActivateBefore(tripData.activateBefore);
            setOnlineSeat(tripData.onlineSeat);
            setOfflineSeat(tripData.offlineSeat);
            handleTripModalOpen();
        }
    }

    const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const value = e.target.value;
        switch (e.target.name) {
            case 'division':
                setDivision(value);
                break;
            case 'ghatFrom':
                setGhatFrom(value);
                break;
            case 'ghatTo':
                setGhatTo(value);
                break;
            default:
                break;
        }
    }

    const handleTripSaveClick = async () => {
        const tripRoute = routes.find((route: any) => route.ghatFrom.id == formGhatFrom && route.ghatTo.id == formGhatTo);

        const postData = {
            id: trip,
            route: {
                id: tripRoute.id
            },
            vessel: {
                id: vessel
            },
            serviceType: serviceType,
            departureTime: `${departureHour}:${departureMin}:${departureSec}`,
            tripDuration: `${tripDurationHour}:${tripDurationMin}:${tripDurationSec}`,
            activateBefore: activateBefore,
            dockNo: dockNo,
            bookingMode: bookingMode,
            onlineSeat: onlineSeat,
            offlineSeat: offlineSeat,
            status: tripStatus
        }
        const saved = await saveTrip(postData);
        
        if (saved) {
            fetchTripSettings(
                routes,
                ghatFrom,
                ghatTo,
                setTripSettingsTableRows,
                setTripList
            );
        }
    }

    const handleInputChange = (updateState: React.Dispatch<React.SetStateAction<string>>) =>
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            updateState(event.target.value);
        };

    return (
        <Body>
            <div className='flex flex-wrap justify-between items-end'>
                <h3 className='flex justify-center items-center gap-4 mb-2'><SettingsRoundedIcon /> Trip Settings</h3>
            </div>
            <Card >
                <div className='flex gap-2 items-end'>
                    <div className='w-full lg:w-1/4'>
                        <FormControl
                            label='Division'
                            type={'select'}
                            id={'division'}
                            value={division}
                            options={divisionOptions}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <div className='w-full lg:w-1/4'>
                        <FormControl
                            label='From'
                            type={'select'}
                            id={'ghatFrom'}
                            value={ghatFrom}
                            options={ghatFromOptions}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <div className='w-full lg:w-1/4'>
                        <FormControl
                            label='To'
                            type={'select'}
                            id={'ghatTo'}
                            value={ghatTo}
                            options={ghatToOptions}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <div className='w-full lg:w-1/4 pb-1'>
                        <DefaultButton
                            onClick={handleFetchSettings}
                            buttonText={'Fetch Trip Settings'}
                            disabledTime={1}
                        />
                    </div>
                </div>
            </Card>

            <TripTable
                tripSettingsTableRows={tripSettingsTableRows}
                handleSettingsEditClick={handleSettingsEditClick}
            />

            <ModalWrapper
                open={tripModalOpen}
                handleClose={handleTripModalClose}
                title={'Trip Details'}>
                <div>
                    <form className='flex flex-wrap'>
                        <input type="text" value={trip} readOnly hidden />
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <FormControl
                                mandatoryField={true}
                                label='From'
                                type={'select'}
                                id={'formGhatFrom'}
                                value={formGhatFrom}
                                onChange={handleInputChange(setFormGhatFrom)}
                                options={formGhatFromOptions}
                            />
                        </div>
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <FormControl
                                mandatoryField={true}
                                label='To'
                                type={'select'}
                                id={'formGhatTo'}
                                value={formGhatTo}
                                onChange={handleInputChange(setFormGhatTo)}
                                options={formGhatToOptions}
                            />
                        </div>
                        <div className='w-full lg:w-1/3'>
                            <FormControl
                                mandatoryField={true}
                                label='Service Type'
                                type={'select'}
                                id={'serviceType'}
                                value={serviceType}
                                onChange={handleInputChange(setServiceType)}
                                options={serviceTypeOptions}
                            />
                        </div>
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <FormControl
                                mandatoryField={true}
                                label='Vessel'
                                type={'select'}
                                id={'formVessel'}
                                value={vessel}
                                options={vesselOptions}
                                onChange={handleInputChange(setVessel)}
                            />
                        </div>
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <label
                                className='block mb-2 text-sm font-medium text-gray-900'
                                htmlFor='departureHour'
                            >
                                Departure Time
                            </label>
                            <div className='flex'>
                                <div className='w-1/3 pr-2'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'departureHour'}
                                        placeholder='HH'
                                        value={departureHour}
                                        onChange={handleInputChange(setDepartureHour)}
                                    />
                                </div>
                                <div className='w-1/3 pr-2'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'departureMin'}
                                        placeholder='MM'
                                        value={departureMin}
                                        onChange={handleInputChange(setDepartureMin)}
                                    />
                                </div>
                                <div className='w-1/3'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'departureSec'}
                                        placeholder='SS'
                                        value={departureSec}
                                        onChange={handleInputChange(setDepartureSec)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:w-1/3'>
                            <label
                                className='block mb-2 text-sm font-medium text-gray-900'
                                htmlFor='durationHour'
                            >
                                Duration
                            </label>
                            <div className='flex'>
                                <div className='w-1/3 pr-2'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'durationHour'}
                                        placeholder='HH'
                                        value={tripDurationHour}
                                        onChange={handleInputChange(setTripDurationHour)}
                                    />
                                </div>
                                <div className='w-1/3 pr-2'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'durationMin'}
                                        placeholder='MM'
                                        value={tripDurationMin}
                                        onChange={handleInputChange(setTripDurationMin)}
                                    />
                                </div>
                                <div className='w-1/3'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'durationSec'}
                                        placeholder='SS'
                                        value={tripDurationSec}
                                        onChange={handleInputChange(setTripDurationSec)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <FormControl
                                mandatoryField={true}
                                label='Status'
                                type={'select'}
                                id={'tripStatus'}
                                value={tripStatus}
                                options={statusOptions}
                                onChange={handleInputChange(setTripStatus)}
                            />
                        </div>
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <FormControl
                                mandatoryField={true}
                                label='Dock No'
                                type={'text'}
                                id={'dockNo'}
                                value={dockNo}
                                onChange={handleInputChange(setDockNo)}
                            />
                        </div>
                        <div className='w-full lg:w-1/3'>
                            <FormControl
                                mandatoryField={true}
                                label='Booking Mode'
                                type={'select'}
                                id={'bookingMode'}
                                value={bookingMode}
                                options={bookingModeOptions}
                                onChange={handleInputChange(setBookingMode)}
                            />
                        </div>
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <FormControl
                                mandatoryField={true}
                                label='Online Seat'
                                type={'number'}
                                id={'onlineSeat'}
                                value={onlineSeat}
                                onChange={handleInputChange(setOnlineSeat)}
                            />
                        </div>
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <FormControl
                                mandatoryField={true}
                                label='Offline Seat'
                                type={'number'}
                                id={'offlineSeat'}
                                value={offlineSeat}
                                onChange={handleInputChange(setOfflineSeat)}
                            />
                        </div>
                        <div className='w-full lg:w-1/3'>
                            <FormControl
                                mandatoryField={true}
                                label='Activate Before (hours)'
                                type={'number'}
                                id={'activateBefore'}
                                value={activateBefore}
                                onChange={handleInputChange(setActivateBefore)}
                            />
                        </div>
                        <div className='w-full mt-4'>
                            <DefaultButton
                                onClick={handleTripSaveClick}
                                buttonText={'Save Trip Details'}
                            />
                        </div>
                    </form>
                </div>
            </ModalWrapper>
        </Body>
    )
}

export default TripSettings