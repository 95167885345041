import React from "react";
import { ReactNode } from "react";

interface CardProps {
    addClass?: string[];
    children: ReactNode;
}

const Card: React.FC<CardProps> = ({ addClass, children }) => {
    return (
        <div className="w-full bg-white rounded-xl shadow">
            <div className={"p-6 space-y-4 md:space-y-6 sm:p-8" +
                (addClass ?
                    addClass.map(cls => (
                        " " + cls
                    )).join('') :
                    ""
                )
            }>
                {children}
            </div>
        </div>
    );
}

export default Card;