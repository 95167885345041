import React from 'react'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import RootComponent from "./RootComponent"
import { AuthProvider } from "./auth/AuthProvider"
import DefaultLoader from './components/loader/DefaultLoader'
import { LoadingProvider, useLoading } from './contexts/LoadingContext'
import { persistor, store } from './store/reducers/store'
import './styles/main.sass'

import { ThemeProvider, createTheme } from '@mui/material'

const THEME = createTheme({
    typography: {
        "fontFamily": `'Montserrat', sans-serif`,
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    }
});

const AppContent: React.FC = () => {
    const { isLoading } = useLoading();

    return (
        <>
            {isLoading && <DefaultLoader />}
            <RootComponent />
        </>
    );
};

export default function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AuthProvider>
                    <ThemeProvider theme={THEME}>
                        <LoadingProvider>
                            <Toaster containerStyle={{ zIndex: 99999 }} />
                            <AppContent />
                        </LoadingProvider>
                    </ThemeProvider>
                </AuthProvider>
            </PersistGate>
        </Provider>
    );
}