import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Body from '../../../components/body/Body';
import DefaultButton from '../../../components/buttons/DefaultButton';
import Card from '../../../components/cards/Card';
import TicketTypeClickable from '../../../components/clickables/TicketTypeClickable';
import FormControl from '../../../components/forms/FormControl';
import ModalWrapper from '../../../components/modals/ModalWrapper';
import apiUrl from '../../../constants/api-constants';
import BicycleIcon from '../../../resources/icons/bicycle-icon.svg';
import BikeIcon from '../../../resources/icons/bike-icon.svg';
import CarIcon from '../../../resources/icons/car-icon.svg';
import LuggageIcon from '../../../resources/icons/luggage-icon.svg';
import SeatIcon from '../../../resources/icons/seat-icon.svg';
import { useApiService } from '../../../hooks/useApiService';
import { useEnumService } from '../../../hooks/useEnumService';
import { validationService } from '../../../services/validationService';
import { dateUtil } from '../../../utility/dateUtil';
import { deckItemUtil } from '../../../utility/deckItemUtil';
import { handleError } from '../../../services/handleError';
import { ticketBookingService } from './ticketBookingService';
import Swal from 'sweetalert2';
import swalConfig from '../../../config/swalConfig';

const TicketBooking: React.FC = () => {

    const { postAuthRequest } = useApiService();
    const { fetchAllItems, fetchGenders, fetchPwd } = useEnumService();
    const [trip, setTrip] = useState<any>('');
    const [itemCapacities, setItemCapacities] = useState<any>('');
    const [itemOptions, setItemOptions] = useState<any[]>([]);
    const [bookingSummary, setBookingSummary] = useState<any[]>([]);
    const [price, setPrice] = useState<any>({});

    const [bookerMobile, setBookerMobile] = useState('');
    const [bookerEmail, setBookerEmail] = useState('');

    const [genderOptions, setGenderOptions] = useState<any[]>([]);
    const [pwdOptions, setPwdOptions] = useState<any[]>([]);

    // PASSENGER STATES
    const [passengerList, setPassengerList] = useState<any[]>([]);
    const [passengerId, setPassengerId] = useState('');
    const [passengerName, setPassengerName] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [passengerOptions, setPassengerOptions] = useState<any[]>([]);

    // VEHICLE STATES
    const [vehicleId, setVehicleId] = useState('');
    const [vehicleList, setVehicleList] = useState<any[]>([]);
    const [item, setItem] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [regNo, setRegNo] = useState('');
    const [model, setModel] = useState('');
    const [owner, setOwner] = useState('');
    const [ownerName, setOwnerName] = useState('');

    // GOODS STATE
    const [goodsId, setGoodsId] = useState('');
    const [goodsList, setGoodsList] = useState<any[]>([]);
    const [weight, setWeight] = useState('');
    const [description, setDescription] = useState('');

    const [passengerDetailsModalOpen, setPassengerDetailsModalOpen] = useState(false);
    const handlePassengerDetailsModalOpen = () => setPassengerDetailsModalOpen(true);
    const handlePassengerDetailsModalClose = () => setPassengerDetailsModalOpen(false);

    const [vehicleDetailsModalOpen, setVehicleDetailsModalOpen] = useState(false);
    const handleVehicleDetailsModalOpen = () => setVehicleDetailsModalOpen(true);
    const handleVehicleDetailsModalClose = () => setVehicleDetailsModalOpen(false);

    const [goodsDetailsModalOpen, setGoodsDetailsModalOpen] = useState(false);
    const handleGoodsDetailsModalOpen = () => setGoodsDetailsModalOpen(true);
    const handleGoodsDetailsModalClose = () => setGoodsDetailsModalOpen(false);

    useEffect(() => {
        fetchGenderOptions();
        fetchPwdOptions();
        fetchItemOptions();

        const storedPassengerList = localStorage.getItem('passengerList');
        const storedVehicleList = localStorage.getItem('vehicleList');
        const storedGoodsList = localStorage.getItem('goodsList');
        if (storedPassengerList) {
            setPassengerList(JSON.parse(storedPassengerList));
        }
        if (storedVehicleList) {
            setVehicleList(JSON.parse(storedVehicleList));
        }
        if (storedGoodsList) {
            setGoodsList(JSON.parse(storedGoodsList));
        }

    }, []);

    useEffect(() => {
        const selectedTrip: any = localStorage.getItem('selectedTrip');
        if (selectedTrip) {
            const trip = JSON.parse(selectedTrip)
            setTrip(trip);
        }
    }, []);

    useEffect(() => {
        if (trip != '') {
            processDeckItems();
        }
    }, [trip]);

    useEffect(() => {
        if (passengerList.length == 0 && vehicleList.length == 0 && goodsList.length == 0) {
            setBookingSummary([])
        } else {
            updatePriceDetails();
        }
        localStorage.setItem('passengerList', JSON.stringify(passengerList));
        localStorage.setItem('vehicleList', JSON.stringify(vehicleList));
        localStorage.setItem('goodsList', JSON.stringify(goodsList));
    }, [passengerList, vehicleList, goodsList]);

    useEffect(() => {
        const options: any = [];
        passengerList.forEach((passenger) => {
            if (!vehicleList.find(vehicle => vehicle.passengerUniqueId == passenger.id)) {
                options.push({
                    value: passenger.id,
                    label: passenger.passengerName,
                });
            }
        });
        setPassengerOptions(options);
    }, [passengerList, vehicleList]);

    const processDeckItems = () => {
        const itemCapacities: any = deckItemUtil.processDeckItemsForTrip(trip);
        setItemCapacities(itemCapacities);
    }

    const fetchItemOptions = async () => {
        const response = await fetchAllItems();
        if (response) {
            const { data } = response;
            setItemOptions(data);
        }
    }

    const fetchGenderOptions = async () => {
        const options: any = await fetchGenders();
        if (options) {
            setGenderOptions(options);
        }
    }

    const fetchPwdOptions = async () => {
        const options: any = await fetchPwd();
        if (options) {
            setPwdOptions(options);
        }
    }

    const handlePasssengerAddClick = () => {
        setPassengerId('');
        setPassengerName('');
        setMobileNo('');
        setEmail('');
        setPwd(pwdOptions[0]?.value);
        setGender(genderOptions[0]?.value);
        setAge('');
        handlePassengerDetailsModalOpen();
    }

    const handlePassengerEditClick = (id: any) => {
        const passenger = passengerList.find(passenger => passenger.id == id);
        if (passenger) {
            setPassengerId(passenger.id);
            setPassengerName(passenger.passengerName);
            setMobileNo(passenger.mobileNo);
            setEmail(passenger.email);
            setPwd(passenger.pwd);
            setGender(passenger.gender);
            setAge(passenger.age);
            handlePassengerDetailsModalOpen();
        } else {
            toast.error('Invalid Passenger Details')
        }
    }

    const handleVehicleAddClick = (itemType: string) => {
        setVehicleType(itemType);
        setVehicleId('');
        setModel('');
        setRegNo('');
        setOwnerName(passengerList.find(passenger => passenger.id == passengerOptions[0]?.value)?.passengerName);
        setOwner(passengerOptions[0]?.value);
        handleVehicleDetailsModalOpen();
    }

    const handleVehicleEditClick = (id: any) => {
        const vehicle = vehicleList.find(vehicle => vehicle.id == id);
        if (vehicle) {
            setPassengerOptions([{
                value: vehicle.passengerUniqueId,
                label: vehicle.ownerName,
            }, ...passengerOptions]);

            setVehicleId(vehicle.id)
            setVehicleType(vehicle.vehicleType);
            setModel(vehicle.model);
            setRegNo(vehicle.regNo);
            setOwnerName(passengerList.find(passenger => passenger.id == vehicle.passengerUniqueId)?.passengerName);
            setOwner(vehicle.passengerUniqueId);
            handleVehicleDetailsModalOpen();
        } else {
            toast.error('Invalid Vehicle Details');
        }
    }

    const handleGoodsAddClick = () => {
        setGoodsId('');
        setWeight('');
        setDescription('');
        handleGoodsDetailsModalOpen();
    }

    const handleGoodsEditClick = (id: any) => {
        const goods = goodsList.find(goods => goods.id == id);
        if (goods) {
            setGoodsId(goods.id)
            setWeight(goods.weight);
            setDescription(goods.description);
            handleGoodsDetailsModalOpen();
        } else {
            toast.error('Invalid Goods Details');
        }
    }

    const handleSavePassengerDetailsClick = async () => {
        const form = document.getElementById('passengerForm') as HTMLFormElement;
        if (form && form.checkValidity()) {
            const id = passengerId ? passengerId : dateUtil.getTimestamp();
            const passenger = {
                id: id,
                passengerName: passengerName,
                mobileNo: mobileNo,
                email: email,
                pwd: pwd,
                gender: gender,
                age: Number(age),
                uniqueId: id.toString()
            }
            const passengerIndex = passengerList.findIndex(p => p.id == passengerId);
            if (passengerIndex != -1) {
                const updatedPassengerList = [...passengerList];
                updatedPassengerList[passengerIndex] = passenger;
                setPassengerList(updatedPassengerList);
                toast.success('Passenger Details Updated');
            } else {
                setPassengerList([...passengerList, passenger]);
                toast.success('Passenger Added');
            }

            handlePassengerDetailsModalClose();
        } else {
            form && form.reportValidity();
        }
    }

    const handleSaveVehicleDetailsClick = async () => {
        const form = document.getElementById('vehicleForm') as HTMLFormElement;
        if (form && form.checkValidity()) {
            const deckItem = trip.tripDeckItems.find((deckItem: any) => deckItem.item.itemCategory == 'Vehicle' && deckItem.item.itemType == vehicleType)
            const vehicle = {
                id: vehicleId ? vehicleId : vehicleList.length + 1,
                model: model,
                regNo: regNo,
                item: deckItem.item,
                vehicleType: vehicleType,
                passengerUniqueId: owner.toString(),
                ownerName: ownerName,
            }
            const vehicleIndex = vehicleList.findIndex(v => v.id == vehicle.id);
            if (vehicleIndex != -1) {
                const updatedVehicleList = [...vehicleList];
                updatedVehicleList[vehicleIndex] = vehicle;
                setVehicleList(updatedVehicleList);
                toast.success('Vehicle Details Updated');
            } else {
                setVehicleList([...vehicleList, vehicle]);
                toast.success('Vehicle Added');
            }
            handleVehicleDetailsModalClose();
        } else {
            form && form.reportValidity();
        }
    }

    const handleSaveGoodsDetailsClick = async () => {
        const form = document.getElementById('goodsForm') as HTMLFormElement;
        if (form && form.checkValidity()) {
            const goods = {
                id: goodsId ? goodsId : goodsList.length + 1,
                description: description,
                weight: weight,
            }
            const goodsIndex = goodsList.findIndex(goods => goods.id == goodsId);
            if (goodsIndex != -1) {
                const updatedGoodsList = [...goodsList];
                updatedGoodsList[goodsIndex] = goods;
                setGoodsList(updatedGoodsList)
                toast.success('Goods Details Updated');
            } else {
                setGoodsList([...goodsList, goods]);
                toast.success('Goods Added');
            }
            handleGoodsDetailsModalClose();
        } else {
            form && form.reportValidity();
        }
    }

    const handlePassengerRemoveClick = (id: any) => {
        const vehicle = vehicleList.find(vehicle => vehicle.passengerUniqueId == id);
        if (vehicle) {
            Swal.fire(swalConfig({
                title: 'The following vehicle will be deleted as it is mapped to the passenger.',
                text: `Reg No: ${vehicle.regNo}, Model: ${vehicle.model}`,
                icon: 'warning',
                confirmButtonText: 'Continue',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
            })).then(async (result) => {
                if (result.isConfirmed) {
                    const filteredList = passengerList.filter(passenger => passenger.id != id);
                    setPassengerList(filteredList);
                }
            });
        } else {
            const filteredList = passengerList.filter(passenger => passenger.id != id);
            setPassengerList(filteredList);
        }
    }

    const handleVehicleRemoveClick = (id: any) => {
        const filteredList = vehicleList.filter(vehicle => vehicle.id != id);
        setVehicleList(filteredList);
    }

    const handleGoodsRemoveClick = (id: any) => {
        const filteredList = goodsList.filter(goods => goods.id != id);
        setGoodsList(filteredList);
    }

    const updatePriceDetails = () => {
        const { tickets, price } = ticketBookingService.updatePriceDetails(trip.tripDeckItems, passengerList, vehicleList, goodsList);
        setBookingSummary(tickets);
        setPrice(price);
    }

    // --------- PROCEED TO PAY ----------
    const handleProceedToPayClick = async () => {
        const form = document.getElementById('bookerForm') as HTMLFormElement;
        if (form && form.checkValidity()) {
            const postData = {
                trip: {
                    id: trip.id
                },
                mobileNo: bookerMobile,
                email: bookerEmail,
                passengers: passengerList,
                vehicles: vehicleList,
                goods: goodsList
            }
            try {
                const response: any = await postAuthRequest(
                    apiUrl.booking.createBooking,
                    postData
                );

                if (response) {
                    console.log(response);
                }

                console.log(JSON.stringify(postData));

            } catch (error) {
                handleError(error);
            }
        } else {
            form && form.reportValidity();
        }
    }

    const handlePassengerInputChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const value = e.target.value;
        switch (e.target.name) {
            case 'passengerName':
                setPassengerName(value);
                break;
            case 'mobileNo':
                if (validationService.isValidMobileInput(value)) {
                    setMobileNo(value);
                }
                break;
            case 'email':
                setEmail(value);
                break;
            case 'age':
                setAge(value);
                break;
            case 'gender':
                setGender(value);
                break;
            case 'pwd':
                setPwd(value);
                break;

            default:
                break;
        }
    }

    const handleVehicleInputChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const value = e.target.value;
        switch (e.target.name) {
            case 'model':
                setModel(value);
                break;
            case 'regNo':
                setRegNo(value);
                break;
            // case 'vehicleType':
            //     setVehicleType(value);
            //     break;
            case 'owner':
                setOwnerName(passengerList.find(passenger => passenger.id == value)?.passengerName);
                setOwner(value);
                break;
            default:
                break;
        }
    }

    const handleGoodsInputChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const value = e.target.value;
        switch (e.target.name) {
            case 'description':
                setDescription(value);
                break;
            case 'weight':
                setWeight(value);
                break;
            default:
                break;
        }
    }

    const handleBookerInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        switch (e.target.name) {
            case 'bookerMobile':
                if (validationService.isValidMobileInput(value)) {
                    setBookerMobile(value);
                }
                break;
            case 'bookerEmail':
                setBookerEmail(value);
                break;
            default:
                break;
        }
    }

    const ticketButtons = [
        { clickHandler: handlePasssengerAddClick, icon: SeatIcon, availability: itemCapacities?.seat, hoverText: 'Book Seat', unitLabel: 'Seats' },
        { clickHandler: () => handleVehicleAddClick('Bicycle'), icon: BicycleIcon, availability: itemCapacities?.bicycleCapacity, hoverText: 'Book Slot', unitLabel: 'Slots' },
        { clickHandler: () => handleVehicleAddClick('Two Wheeler'), icon: BikeIcon, availability: itemCapacities?.twoWheelerCapacity, hoverText: 'Book Slot', unitLabel: 'Slots' },
        { clickHandler: () => handleVehicleAddClick('Four Wheeler'), icon: CarIcon, availability: itemCapacities?.fourWheelerCapacity, hoverText: 'Book Slot', unitLabel: 'Slots' },
        { clickHandler: handleGoodsAddClick, icon: LuggageIcon, availability: itemCapacities?.goodsCapacity, hoverText: 'Book Slot', unitLabel: 'Qls' },
    ]

    const generateTicketDetailsTable = (cols: any, rows: any) => {
        return (
            <div className='overflow-x-scroll md:overflow-x-auto'>
                <table className='w-full'>
                    <thead>
                        <tr className='bg-sky-50'>
                            <th></th>
                            {cols.map((col: string, index: number) => (
                                <th key={index} className='text-start px-2 py-2 font-medium'>{col}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows?.map((row: any, index: number) => (
                            <tr key={index}>
                                <td className='px-2 py-2 w-10'>
                                    <div className='flex h-full gap-4 items-center'>
                                        <button className='text-sky-500' onClick={row.handleEditClick}>
                                            <BorderColorRoundedIcon />
                                        </button>
                                        <button className='text-red-400' onClick={row.handleRemoveClick}>
                                            <CancelRoundedIcon />
                                        </button>
                                    </div>
                                </td>
                                {row.fields.map((field: any) => (
                                    <td className='px-2 py-2' key={field.name}>{field.value}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <>
            <Body>
                <div>
                    <h1>Ticket Booking</h1>
                    <Card>
                        <div className='flex flex-wrap items-center -mt-2'>
                            <div className='w-full lg:w-4/6 lg:pr-2'>
                                <div className='w-full'>
                                    <h3 className='m-0 text-sky-500'>
                                        {trip.vessel?.name}
                                    </h3>
                                </div>
                                <div className='mb-1'>
                                    Book Tickets
                                </div>
                                <div className="flex mb-3 flex-wrap">
                                    {
                                        ticketButtons.map((button, index) => (
                                            <div className="w-1/2 lg:w-1/5" key={index}>
                                                <button className='w-full'
                                                    onClick={button.clickHandler}>
                                                    <TicketTypeClickable
                                                        icon={button.icon}
                                                        availability={button.availability}
                                                        hoverText={button.hoverText}
                                                        unitLabel={button.unitLabel}
                                                    />
                                                </button>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            {/* ---------- TRIP DETAILS ---------- */}
                            <div className='w-full lg:w-2/6 lg:px-2 lg:ps-10 h-full'>
                                <div className="flex flex-col justify-between h-full">
                                    <h4 className='m-0 mb-2 text-sky-500'>
                                        Trip Details
                                    </h4>
                                    <div>
                                        <div className="flex">
                                            <div className='my-1 w-1/2 px-2'>
                                                <div className='text-sm text-neutral-500 font-semibold'>Date</div>
                                                <div className='font-semibold text-neutral-600'>{trip && dateUtil.formatDateWithDay(trip?.tripDate)}</div>
                                            </div>
                                            <div className='my-1 w-1/2 px-2'>
                                                <div className='text-sm text-neutral-500 font-semibold'>Departure</div>
                                                <div className='font-semibold text-neutral-600'>{trip && dateUtil.formatTime(trip?.departureTime)}</div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="flex">
                                            <div className='my-1 w-1/2 px-2'>
                                                <div className='text-sm text-neutral-500 font-semibold'>Source</div>
                                                <div className='font-semibold text-neutral-600'>{trip?.route?.ghatFrom.ghatName}</div>
                                            </div>
                                            <div className='my-1 w-1/2 px-2'>
                                                <div className='text-sm text-neutral-500 font-semibold'>Destination</div>
                                                <div className='font-semibold text-neutral-600'>{trip?.route?.ghatTo.ghatName}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>

                    {/* ---------- TICKET DETAILS ---------- */}
                    {
                        (passengerList.length > 0 || vehicleList.length > 0 || goodsList.length > 0) &&
                        <div className='w-full flex flex-wrap'>
                            <div className="w-full lg:w-8/12 lg:pr-2 h-full mt-4">
                                <h4 className='mb-2'>Ticket Details</h4>
                                <Card addClass={["h-full"]}>
                                    {
                                        passengerList.length > 0 &&
                                        <div>
                                            <div className='mb-1 font-semibold'>Passenger Details:</div>
                                            {generateTicketDetailsTable(
                                                ['Name', 'Age', 'Gender'],
                                                passengerList.map(passenger => ({
                                                    handleEditClick: () => handlePassengerEditClick(passenger.id),
                                                    handleRemoveClick: () => handlePassengerRemoveClick(passenger.id),
                                                    fields: [
                                                        { name: 'passengerName', value: passenger.passengerName },
                                                        { name: 'passengerAge', value: passenger.age },
                                                        { name: 'passengerGender', value: passenger.gender },
                                                    ]
                                                }))
                                            )}
                                        </div>
                                    }
                                    {
                                        vehicleList.length > 0 &&
                                        <div>
                                            <div className='mb-1 font-semibold'>Vehicle Details:</div>
                                            {generateTicketDetailsTable(
                                                ['Type', 'Reg No', 'Model', 'Owner'],
                                                vehicleList.map(vehicle => ({
                                                    handleEditClick: () => handleVehicleEditClick(vehicle.id),
                                                    handleRemoveClick: () => handleVehicleRemoveClick(vehicle.id),
                                                    fields: [
                                                        { name: 'vehicleType', value: vehicle.item.itemType },
                                                        { name: 'regNo', value: vehicle.regNo },
                                                        { name: 'model', value: vehicle.model },
                                                        { name: 'ownerName', value: vehicle.ownerName },
                                                    ]
                                                }))
                                            )}
                                        </div>
                                    }
                                    {
                                        goodsList.length > 0 &&
                                        <div>
                                            <div className='mb-1 font-semibold'>Vehicle Details:</div>
                                            {generateTicketDetailsTable(
                                                ['Description', 'Weight'],
                                                goodsList.map(goods => ({
                                                    handleEditClick: () => handleGoodsEditClick(goods.id),
                                                    handleRemoveClick: () => handleGoodsRemoveClick(goods.id),
                                                    fields: [
                                                        { name: 'description', value: goods.description },
                                                        { name: 'weight', value: goods.weight },
                                                    ]
                                                }))
                                            )}
                                        </div>
                                    }
                                </Card>
                            </div>
                            <div className="w-full lg:w-4/12 lg:pl-2 h-full mt-4">
                                <h4 className='mb-2'>Booking Summary</h4>
                                <Card addClass={["h-full"]}>
                                    <div className=''>
                                        <table className="w-full border-collapse mb-4">
                                            <thead>
                                                <tr className='text-left'>
                                                    <th className="p-2">Ticket Type</th>
                                                    <th className="p-2 text-right">Price</th>
                                                    <th className="p-2 text-right">Qty</th>
                                                    <th className="p-2 text-right">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    bookingSummary.map((summary, index) => (
                                                        <tr key={index}>
                                                            <td className="p-2 break-words">{summary.itemDetails}</td>
                                                            <td className="p-2 text-right">{summary.itemPrice}</td>
                                                            <td className="p-2 text-right">{summary.itemQuantity}</td>
                                                            <td className="p-2 text-right">{summary.itemTotalPrice}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <div className="border-t border-zinc-300 pt-4 px-2">
                                            <div className="flex justify-between mb-2">
                                                <span>Net Amount</span>
                                                <span>{price.netAmount}</span>
                                            </div>
                                            <div className="flex justify-between mb-2">
                                                <span>Convenience Fee</span>
                                                <span>{price.convenienceFee}</span>
                                            </div>
                                            <div className="flex justify-between font-bold">
                                                <span>Total</span>
                                                <span>{price.totalPrice}</span>
                                            </div>
                                        </div>

                                        <div className="border-t border-zinc-300 pt-4 px-2 mt-4">
                                            <form id='bookerForm' className='w-full space-y-4'>
                                                <div className='text-sm font-medium'>Contact Details for Ticket Delivery</div>
                                                <FormControl
                                                    type={'text'}
                                                    id={'bookerMobile'}
                                                    placeholder='Mobile No'
                                                    value={bookerMobile}
                                                    onChange={handleBookerInputChange}
                                                    mandatoryField={true}
                                                />
                                                <FormControl
                                                    type={'email'}
                                                    id={'bookerEmail'}
                                                    placeholder='Email'
                                                    value={bookerEmail}
                                                    onChange={handleBookerInputChange}
                                                    mandatoryField={true}
                                                />
                                            </form>

                                        </div>
                                        <div className='mt-4 px-2'>
                                            <DefaultButton
                                                onClick={handleProceedToPayClick}
                                                buttonText={"Proceed to Pay"}
                                            >
                                            </DefaultButton>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    }
                </div>
            </Body >

            {/*
             ----------------------------------
             ----- PASSENGER DETAILS MODAL ----
             ----------------------------------
            */}
            < ModalWrapper
                open={passengerDetailsModalOpen}
                handleClose={handlePassengerDetailsModalClose}
                title={'Passenger Details'} >
                <form id="passengerForm" className='flex flex-wrap w-full'>
                    <div className='w-full mb-3 lg:w-2/4 lg:pr-4'>
                        <input type="text" value={passengerId} readOnly hidden />
                        <FormControl
                            label='Name'
                            type={'text'}
                            id={'passengerName'}
                            value={passengerName}
                            onChange={handlePassengerInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full mb-3 lg:w-1/4 lg:pr-3'>
                        <FormControl
                            label='Age'
                            type={'number'}
                            id={'age'}
                            value={age}
                            onChange={handlePassengerInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full mb-3 lg:w-1/4'>
                        <FormControl
                            type={'select'}
                            id={'gender'}
                            label='Gender'
                            options={genderOptions}
                            value={gender}
                            onChange={handlePassengerInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full mb-3 lg:w-1/3 lg:pr-3'>
                        <FormControl
                            label='Mobile'
                            type={'text'}
                            id={'mobileNo'}
                            value={mobileNo}
                            onChange={handlePassengerInputChange}
                        />
                    </div>
                    <div className='w-full mb-3 lg:w-1/3 lg:pr-3'>
                        <FormControl
                            label='Email'
                            type={'email'}
                            id={'email'}
                            value={email}
                            onChange={handlePassengerInputChange}
                        />
                    </div>
                    <div className='w-full mb-3 lg:w-1/3'>
                        <FormControl
                            type={'select'}
                            id={'pwd'}
                            label='Differently Abled?'
                            options={pwdOptions}
                            value={pwd}
                            onChange={handlePassengerInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='flex justify-center w-full mt-3'>
                        <DefaultButton
                            onClick={handleSavePassengerDetailsClick}
                            buttonText={"Save Passenger Details"}
                        />
                    </div>
                </form>
            </ModalWrapper >

            {/*
             ----------------------------------
             ------ VEHICLE DETAILS MODAL -----
             ----------------------------------
            */}
            < ModalWrapper
                open={vehicleDetailsModalOpen}
                handleClose={handleVehicleDetailsModalClose}
                title={'Vehicle Details'} >
                <h6 className='text-sky-500 text-center mb-4'>Vehicle Type: {vehicleType}</h6>
                <form id='vehicleForm' className='flex flex-wrap w-full'>
                    <div className='w-full mb-3 lg:w-1/3 lg:pr-4'>
                        <input type="text" value={vehicleId} readOnly hidden />
                        <FormControl
                            label='Vehicle Model'
                            type={'text'}
                            id={'model'}
                            value={model}
                            onChange={handleVehicleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full mb-3 lg:w-1/3 lg:pr-3'>
                        <FormControl
                            label='Registration No'
                            type={'text'}
                            id={'regNo'}
                            value={regNo}
                            onChange={handleVehicleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full mb-3 lg:w-1/3'>
                        <FormControl
                            label='Owner'
                            type={'select'}
                            id={'owner'}
                            value={owner}
                            options={passengerOptions}
                            onChange={handleVehicleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='flex justify-center w-full mt-3'>
                        <DefaultButton
                            onClick={handleSaveVehicleDetailsClick}
                            buttonText={"Save Vehicle Details"}
                        />
                    </div>
                </form>
            </ModalWrapper >

            {/*
             ----------------------------------
             ------- GOODS DETAILS MODAL ------
             ----------------------------------
            */}
            <ModalWrapper
                open={goodsDetailsModalOpen}
                handleClose={handleGoodsDetailsModalClose}
                title={'Goods Details'} >
                <form id='goodsForm' className='flex flex-wrap w-full'>
                    <div className='w-full mb-3 lg:w-1/4'>
                        <input type="text" value={goodsId} readOnly hidden />
                        <FormControl
                            label='Weight(qls)'
                            type={'number'}
                            id={'weight'}
                            value={weight}
                            onChange={handleGoodsInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full mb-3 lg:w-3/4 lg:pl-4'>
                        <FormControl
                            label='Description'
                            type={'text'}
                            id={'description'}
                            value={description}
                            onChange={handleGoodsInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='flex justify-center w-full mt-3'>
                        <DefaultButton
                            onClick={handleSaveGoodsDetailsClick}
                            buttonText={"Save Goods Details"}
                        />
                    </div>
                </form>
            </ModalWrapper >
        </>
    )
}

export default TicketBooking;