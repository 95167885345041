import React, { useState } from 'react';

interface DefaultButtonProps {
    onClick: () => Promise<void>;
    disabledTime?: number; // Optional: Time in milliseconds to keep button disabled after click
    buttonText: string;
    buttonIcon?: React.ElementType;
}

const DefaultButton: React.FC<DefaultButtonProps> = ({ onClick, disabledTime = 2000, buttonText, buttonIcon: ButtonIcon }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleClick = async () => {
        setIsDisabled(true);
        setIsLoading(true);

        try {
            await onClick();
        } catch (error) {
            console.error('Error occurred:', error);
        } finally {
            // Enable button after disabledTime (if specified)
            if (disabledTime > 0) {
                setTimeout(() => {
                    setIsDisabled(false);
                    setIsLoading(false);
                }, disabledTime);
            } else {
                setIsDisabled(false);
                setIsLoading(false);
            }
        }
    };

    return (
        <button type="submit" onClick={handleClick} disabled={isDisabled}
            className="w-full text-white bg-sky-600 hover:bg-sky-700 focus:ring-4 
                    transition duration-300 focus:outline-none focus:ring-sky-300 font-medium rounded-lg 
                    px-5 text-center"
            style={{
                paddingTop: '0.575rem',
                paddingBottom: '0.575rem'
            }}        
            >
            {isLoading ? 'Loading...' : buttonText}
            {ButtonIcon && !isLoading && <ButtonIcon className='ml-2 -mt-1' />}
        </button>
    );
};

export default DefaultButton;