import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

export const handleError = async (error: AxiosError | any) => {
    if (error.response) {
        const { data } = error.response;
        if (data.errorType === "FORM_ERR") {
            const { object } = error.response.data;
            const errors: any = {};
            object.forEach((item: any) => {
                errors[item.fieldName] = item.message;
            });
            return errors;

        } else if (data.errors && data.errors.length > 0) {
            const errors = data.errors;
            errors.forEach((error: string) => {
                toast.error(error);
            });

        } else {
            toast.error(data.message || 'An error occurred');
        }
    } else if (error.request) {
        toast.error('No response received from the server');
    } else {
        toast.error('Error occurred while processing the request');
    }
};
