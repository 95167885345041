import React from 'react'
import PopoverDetails from '../../../components/popovers/PopoverDetails'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import TableActionButton from '../../../components/buttons/TableActionButton'
import { BorderColor } from '@mui/icons-material'

interface TripTableProps {
    tripSettingsTableRows: any[]
    handleSettingsEditClick: (id: string) => void
}

const TripTable: React.FC<TripTableProps> = ({ tripSettingsTableRows, handleSettingsEditClick }) => {

    const tripSettingsTableCols: GridColDef[] = [
        { field: 'slNo', headerName: 'Sl No' },
        {
            headerName: 'Vessel',
            flex: 2,
            field: 'vessel',
            renderCell: (params: any) => {
                const { vessel } = params.row;
                return (`${vessel.name} (${vessel.regNo})`);
            },
        },
        { field: 'departureTime', headerName: 'Dept. Time', flex: 2 },
        { field: 'tripDuration', headerName: 'Duration', flex: 2 },
        { field: 'dockNo', headerName: 'Dock No', flex: 2 },
        { field: 'serviceType', headerName: 'Service Type', flex: 2 },
        { field: 'status', headerName: 'Status', flex: 1 },
        {
            align: 'center',
            flex: 2,
            minWidth: 150,
            headerAlign: 'center',
            field: 'capacity',
            headerName: 'Capacity',
            sortable: false,
            filterable: false,
            renderCell: (params: any) => {
                const { id, deckItems } = params.row;
                return (
                    <PopoverDetails buttonText={'View Capacity'}>
                        <table key={id} className='w-full'>
                            <tbody className='text-sm'>
                                {
                                    deckItems.map((deck: any) => (
                                        <React.Fragment key={deck.deckId}>
                                            <tr className='border bg-sky-100'>
                                                <td colSpan={2} className='px-2 py-1'>{deck.deckNo}</td>
                                            </tr>
                                            {
                                                deck.items.map((item: any) => (
                                                    <tr key={item.itemType}>
                                                        <td className='w-3/4 border px-2 py-1'>{item.itemType} ({item.unitType})</td>
                                                        <td className='w-1/4 border px-2 py-1'>{item.capacityValue}</td>
                                                    </tr>
                                                ))
                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </tbody>
                        </table>
                    </PopoverDetails>
                );
            },
        },
        {
            align: 'center',
            flex: 2,
            minWidth: 150,
            headerAlign: 'center',
            field: 'action',
            headerName: 'Action',
            sortable: false,
            filterable: false,
            renderCell: (params: any) => {
                const { id } = params.row;
                return (
                    <div className='flex gap-2 justify-center items-center h-full'>
                        <TableActionButton
                            type='primary'
                            onClick={async () => handleSettingsEditClick(id)}>
                            <BorderColor fontSize='small' />
                        </TableActionButton>
                    </div>
                );
            },
        }
    ]

    return (
        <div className='mt-3 bg-white'>
            <div className='flex justify-center'>
                <div className='flex w-full'>
                    <DataGrid
                        rows={tripSettingsTableRows}
                        columns={tripSettingsTableCols}
                        autosizeOnMount
                        autoHeight
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 15]}
                    />
                </div>
            </div>
        </div>
    )
}

export default TripTable