const processDeckItems = (searchResults: any[]) => {
    const itemList: any = [];
    searchResults.forEach((result: any) => {
        let bicycleCapacity = 0;
        let twoWheelerCapacity = 0;
        let fourWheelerCapacity = 0;
        let goodsCapacity = 0;
        result.tripDeckItems.forEach((deckItem: any) => {
            switch (deckItem.item.itemType) {
                case 'Bicycle':
                    bicycleCapacity = bicycleCapacity + deckItem.availableValue;
                    break;
                case 'Two Wheeler':
                    twoWheelerCapacity = twoWheelerCapacity + deckItem.availableValue;
                    break;
                case 'Four Wheeler':
                    fourWheelerCapacity = fourWheelerCapacity + deckItem.availableValue;
                    break;
                case 'Goods':
                    goodsCapacity = goodsCapacity + deckItem.availableValue;
                    break;
                default:
                    break;
            }
        })
        const items = {
            trip: result.id,
            seat: result.onlineSeatAvailable,
            bicycleCapacity: bicycleCapacity,
            twoWheelerCapacity: twoWheelerCapacity,
            fourWheelerCapacity: fourWheelerCapacity,
            goodsCapacity: goodsCapacity,
        }

        itemList.push(items);
    });

    return itemList;
}

const processDeckItemsForTrip = (trip: any) => {

    let bicycleCapacity = 0;
    let twoWheelerCapacity = 0;
    let fourWheelerCapacity = 0;
    let goodsCapacity = 0;

    trip.tripDeckItems.forEach((deckItem: any) => {
        switch (deckItem.item.itemType) {
            case 'Bicycle':
                bicycleCapacity = bicycleCapacity + deckItem.availableValue;
                break;
            case 'Two Wheeler':
                twoWheelerCapacity = twoWheelerCapacity + deckItem.availableValue;
                break;
            case 'Four Wheeler':
                fourWheelerCapacity = fourWheelerCapacity + deckItem.availableValue;
                break;
            case 'Goods':
                goodsCapacity = goodsCapacity + deckItem.availableValue;
                break;
            default:
                break;
        }
    });

    const items = {
        trip: trip.id,
        seat: trip.onlineSeatAvailable,
        bicycleCapacity: bicycleCapacity,
        twoWheelerCapacity: twoWheelerCapacity,
        fourWheelerCapacity: fourWheelerCapacity,
        goodsCapacity: goodsCapacity,
    }

    return items;
}

export const deckItemUtil = {
    processDeckItems,
    processDeckItemsForTrip
}