import { AddRounded, DirectionsBoat } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ActionMeta } from 'react-select'
import Body from '../../../components/body/Body'
import DefaultButton from '../../../components/buttons/DefaultButton'
import Card from '../../../components/cards/Card'
import FormControl from '../../../components/forms/FormControl'
import SelectWrapper from '../../../components/forms/SelectWrapper'
import ModalWrapper from '../../../components/modals/ModalWrapper'
import { ROUTES } from '../../../constants/routes-constants'
import { SelectOption } from '../../../constants/type-constants'
import { useVesselManagementService } from './useVesselManagementService'
import VesselTable from './VesselTable'

const VesselManagement: React.FC = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { divisionId } = location.state || { divisionId: '' };

    const {
        fetchDivisionOptions,
        fetchGhatOptions,
        fetchVesselTypeOptions,
        fetchStatusOptions,
        fetchOwnerOptions,
        fetchNightNaviationOptions,
        fetchWeekdaysOptions,
        fetchVesselsByDivisions,
        saveVessel
    } = useVesselManagementService();

    // ------------------------------------------------------------------------
    // --------------------------- options ------------------------------------
    // ------------------------------------------------------------------------
    const [divisionOptions, setDivisionOptions] = useState<SelectOption[]>([]);
    const [vesselTypeOptions, setVesselTypeOptions] = useState<SelectOption[]>([]);
    const [ownerOptions, setOwnerOptions] = useState<SelectOption[]>([]);
    const [hasNightNavigationOptions, setHasNightNavigationOptions] = useState<SelectOption[]>([]);
    const [statusOptions, setStatusOptions] = useState<SelectOption[]>([]);
    const [ghatOptions, setGhatOptions] = useState<SelectOption[]>([]);
    const [weekdaysOptions, setWeekdaysOptions] = useState<any[]>([]);

    useEffect(() => {
        fetchDivisionOptions(setDivisionOptions, setDivision, divisionId);
        fetchVesselTypeOptions(setVesselTypeOptions, setVesselType);
        fetchStatusOptions(setStatusOptions, setStatus);
        fetchOwnerOptions(setOwnerOptions, setOwner);
        fetchNightNaviationOptions(setHasNightNavigationOptions, setHasNightNavigation);
        fetchWeekdaysOptions(setWeekdaysOptions, setSelectedDays);
    }, []);

    // ./options
    // ------------------------------------------------------------------------

    const [division, setDivision] = useState('');
    const [vessel, setVessel] = useState<string>('');
    const [vesselList, setVesselList] = useState<any[]>([]);
    const [vesselTableRows, setVesselTableRows] = useState<any[]>([]);
    const [selectedDays, setSelectedDays] = useState<any>({});

    // ------------------------------------------------------------------------
    // ----------------------------- form -------------------------------------
    // ------------------------------------------------------------------------
    const [vesselName, setVesselName] = useState<string>('');
    const [vesselRegNo, setVesselRegNo] = useState<string>('');
    const [vesselType, setVesselType] = useState<string>('');
    const [owner, setOwner] = useState<string>('');
    const [hasNightNavigation, setHasNightNavigation] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [vesselDivisions, setVesselDivisions] = useState<any[]>([]);
    const [baseGhat, setBaseGhat] = useState<string>('');

    // ./form
    // ------------------------------------------------------------------------

    useEffect(() => {
        if (divisionId != '') {
            fetchVesselsByDivisions(
                divisionId,
                setVesselTableRows,
                setVesselList
            );
        }
    }, [divisionId]);

    const [vesselModalOpen, setVesselModalOpen] = useState<boolean>(false);
    const handleVesselModalOpen = () => setVesselModalOpen(true);
    const handleVesselModalClose = () => setVesselModalOpen(false);

    const handleInputChange = (updateState: React.Dispatch<React.SetStateAction<string>>) =>
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            updateState(event.target.value);
        };

    const handleSelectInputChange = (newValue: any, actionMeta: ActionMeta<any>) => {
        setVesselDivisions(newValue);
    }

    const handleCheckboxChange = (id: any) => {
        setSelectedDays((prevState: any) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleFetchVesselsClick = async () => {
        fetchVesselsByDivisions(
            division,
            setVesselTableRows,
            setVesselList
        );
        fetchGhatOptions(division, setGhatOptions);
    };

    const handleVesselEditClick = async (id: string) => {
        const v = vesselList.find((vessel: any) => vessel.id == id);

        setVessel(v.id);
        setVesselName(v.name);
        setVesselRegNo(v.regNo);
        setVesselType(v.vesselType);
        setOwner(v.owner);
        setHasNightNavigation(v.hasNightNavigation);
        setStatus(v.status);
        setVesselDivisions(v.divisions.map((div: any) => (
            { value: div.id, label: div.divisionName }
        )));
        setBaseGhat(v.baseGhat.id);
        setSelectedDays([]);
        setSelectedDays((prevSelectedDays: any) => {
            const updatedSelectedDays = { ...prevSelectedDays };
            v.tripDays.forEach((day: any) => {
                updatedSelectedDays[day.id] = true;
            });
            return updatedSelectedDays;
        });
        handleVesselModalOpen();
    }

    const handleAddButtonClick = async () => {
        if (!ghatOptions.length) {
            fetchGhatOptions(division, setGhatOptions);
        }
        setVessel('');
        setVesselName('');
        setVesselRegNo('');
        setVesselType(vesselTypeOptions[0]?.value);
        setOwner(ownerOptions[0]?.value);
        setHasNightNavigation(hasNightNavigationOptions[0]?.value);
        setStatus(statusOptions[0]?.value);
        setVesselDivisions([]);
        setBaseGhat(ghatOptions[0]?.value);
        setSelectedDays([]);
        handleVesselModalOpen();
    }

    const handleVesselSaveClick = async () => {
        const selectedDayIds = Object.keys(selectedDays)
            .filter(id => selectedDays[id])
            .map(id => ({ id: parseInt(id, 10) }));

        const vd = vesselDivisions.map(division => ({ id: division.value }))
        const postData = {
            id: vessel,
            baseGhat: {
                id: baseGhat
            },
            divisions: vd,
            tripDays: selectedDayIds,
            name: vesselName,
            regNo: vesselRegNo,
            vesselType: vesselType,
            owner: owner,
            hasNightNavigation: hasNightNavigation,
            status: status
        }

        saveVessel(
            postData,
            division,
            setVesselTableRows,
            setVesselList,
            handleVesselModalClose,
        )
    }

    const handleVesselMatrixClick = async (id: any) => {
        navigate(ROUTES.MANAGE.VESSEL_MATRIX,
            {
                state: {
                    vesselId: id,
                    divisionId: division
                }
            })
    }

    return (
        <Body>
            <div className='flex flex-wrap justify-between items-end'>
                <h3 className='flex justify-center items-center gap-4 mb-2'><DirectionsBoat /> Vessel Management</h3>
            </div>
            <Card>
                <div className='flex w-full justify-between'>
                    <div className='flex gap-2 items-end'>
                        <FormControl
                            label='Division'
                            type={'select'}
                            id={'division'}
                            value={division}
                            onChange={handleInputChange(setDivision)}
                            options={divisionOptions}
                        />
                        <div className='pb-1'>
                            <DefaultButton
                                onClick={handleFetchVesselsClick}
                                buttonText={'Fetch Vessels'}
                                disabledTime={1}
                            />
                        </div>
                    </div>
                    <div className='flex flex-wrap justify-between items-end' >
                        <div className='flex w-max mt-4 mb-2'>
                            <DefaultButton
                                onClick={handleAddButtonClick}
                                buttonText='Add New Vessel'
                                disabledTime={0}
                                buttonIcon={AddRounded}
                            />
                        </div>
                    </div>
                </div>
            </Card>

            <div className='mt-3'>
                <div className='flex justify-center'>
                    <div className='flex w-full bg-white'>
                        <VesselTable
                            vesselTableRows={vesselTableRows}
                            handleVesselEditClick={handleVesselEditClick}
                            handleVesselMatrixClick={handleVesselMatrixClick} />
                    </div>
                </div>
            </div>

            <ModalWrapper
                open={vesselModalOpen}
                handleClose={handleVesselModalClose}
                title={'Vessel Details'}
            >
                <form className='flex flex-wrap'>
                    <div className='w-full lg:w-2/3 lg:pr-4'>
                        <input type='text' id='id' name='id' value={vessel} readOnly hidden />
                        <FormControl
                            label='Vessel Name'
                            type={'text'}
                            id={'vesselName'}
                            value={vesselName}
                            onChange={handleInputChange(setVesselName)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3'>
                        <FormControl
                            label='Registration Number'
                            type={'text'}
                            id={'regNo'}
                            value={vesselRegNo}
                            onChange={handleInputChange(setVesselRegNo)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3 lg:pr-4'>
                        <FormControl
                            label='Vessel Type'
                            type={'select'}
                            id={'vesselType'}
                            value={vesselType}
                            options={vesselTypeOptions}
                            onChange={handleInputChange(setVesselType)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3 lg:pr-4'>
                        <FormControl
                            label='Owner Type'
                            type={'select'}
                            id={'owner'}
                            value={owner}
                            options={ownerOptions}
                            onChange={handleInputChange(setOwner)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3'>
                        <FormControl
                            label='Has Night Navigation?'
                            type={'select'}
                            id={'hasNightNavigation'}
                            value={hasNightNavigation}
                            options={hasNightNavigationOptions}
                            onChange={handleInputChange(setHasNightNavigation)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3 lg:pr-4'>
                        <FormControl
                            label='Status'
                            type={'select'}
                            id={'status'}
                            value={status}
                            options={statusOptions}
                            onChange={handleInputChange(setStatus)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3 lg:pr-4'>
                        <SelectWrapper
                            label='Division'
                            defaultValue={vesselDivisions}
                            id={'vesselDivision'}
                            options={divisionOptions}
                            onChange={handleSelectInputChange}
                            isMulti={true}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3'>
                        <FormControl
                            label='Base Ghat'
                            type={'select'}
                            id={'baseGhat'}
                            value={baseGhat}
                            options={ghatOptions}
                            onChange={handleInputChange(setBaseGhat)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-3/5'>
                        <label htmlFor="weekdaysSelect" className='text-sm font-medium text-gray-900'>Weekdays <span className='text-red-500'>*</span></label>
                        <div id='weekdaysSelect' className='flex gap-2 mt-2'>
                            {weekdaysOptions.map(day => (
                                <div key={day.id}>
                                    <label className=
                                        {`block w-10 cursor-pointer text-center py-2 text-white duration-300 rounded-full 
                                            ${selectedDays[day.id] || false ?
                                                `bg-sky-400`
                                                :
                                                `bg-neutral-200`
                                            }`}
                                    >
                                        <input
                                            hidden
                                            type="checkbox"
                                            checked={selectedDays[day.id] || false}
                                            onChange={() => handleCheckboxChange(day.id)}
                                        />
                                        {day.dayName[0]}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </form>
                <div className='mt-4'>
                    <DefaultButton
                        onClick={handleVesselSaveClick}
                        buttonText={'Save Vessel Details'}
                    />
                </div>
            </ModalWrapper>

        </Body>
    )
}

export default VesselManagement