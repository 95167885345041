import React, { useEffect, useState } from 'react';
import Body from '../../components/body/Body';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import SeatIcon from '../../resources/icons/seat-icon.svg';
import BikeIcon from '../../resources/icons/bike-icon.svg';
import CarIcon from '../../resources/icons/car-icon.svg';
import LuggageIcon from '../../resources/icons/luggage-icon.svg';
import BicycleIcon from '../../resources/icons/bicycle-icon.svg';

import Card from '../../components/cards/Card';
import DefaultButton from '../../components/buttons/DefaultButton';
import { useNavigate } from 'react-router-dom';
import { dateUtil } from '../../utility/dateUtil';
import { ROUTES } from '../../constants/routes-constants';
import { deckItemUtil } from '../../utility/deckItemUtil';

const SearchResults: React.FC = () => {
    const navigate = useNavigate();

    const [route, setRoute] = useState<{
        id: any,
        ghatFrom: { id: any, ghatName: any }
        ghatTo: { id: any, ghatName: any }
    }>({
        id: '',
        ghatFrom: { id: '', ghatName: '' },
        ghatTo: { id: '', ghatName: '' }
    });

    const [tripDate, setTripDate] = useState('');
    const [itemList, setItemList] = useState<any[]>([]);
    const [searchResults, setSearchResults] = useState<any[]>([]);


    useEffect(() => {
        const storedResults = localStorage.getItem('searchResults');
        if (storedResults) {
            setSearchResults(JSON.parse(storedResults));
        } else {

            navigate(ROUTES.INDEX);
        }
    }, []);

    useEffect(() => {
        if (searchResults.length > 0) {
            setRoute(searchResults[0]?.route);
            setTripDate(dateUtil.formatDateWithDay(searchResults[0]?.tripDate));
            processDeckItems();
        }
    }, [searchResults]);

    const processDeckItems = () => {
        const itemList: any = deckItemUtil.processDeckItems(searchResults);
        setItemList(itemList);
    }


    const handleBookTicketClick = async (tripId: any) => {
        const trip = searchResults.find((result: any) => result.id == tripId);
        if (trip) {
            // localStorage.setItem('tripId', JSON.stringify(tripId));
            localStorage.setItem('selectedTrip', JSON.stringify(trip));
            localStorage.removeItem('searchResults');
            navigate("/book-tickets");
        } else {
            alert('Not Found!');
        }
    }

    return (
        <Body>
            <div className='lg:mx-36'>
                <div className='border rounded-lg py-2 px-8 border-sky-100 mb-4 bg-white bg-opacity-50'>
                    <div className='text-lg font-semibold text-sky-500'>
                        Vessel List
                    </div>
                    <div className='flex flex-wrap w-full md:justify-between'>
                        <div className='w-full md:w-1/3 mb-3 md:mb-0'>
                            <div className='text-sm text-neutral-500 font-semibold'>Origin</div>
                            <div className='font-semibold text-neutral-600'>{route.ghatFrom.ghatName}</div>
                        </div>
                        <div className='w-full md:w-1/3 mb-3 md:mb-0'>
                            <div className='text-sm text-neutral-500 font-semibold'>Destination</div>
                            <div className='font-semibold text-neutral-600'>{route.ghatTo.ghatName}</div>
                        </div>
                        <div className='w-full md:w-1/3 mb-3 md:mb-0'>
                            <div className='text-sm text-neutral-500 font-semibold'>Date</div>
                            <div className='font-semibold text-neutral-600'>{tripDate}</div>
                        </div>
                    </div>
                </div>
                {
                    searchResults.length > 0 &&
                    searchResults.map((result: any) => {
                        const itemSectionContents = [
                            { icon: SeatIcon, count: result.onlineSeatAvailable, label: 'Seats Available' },
                            { icon: BicycleIcon, count: itemList.find(item => item.trip == result.id)?.bicycleCapacity, label: 'Slots Available' },
                            { icon: BikeIcon, count: itemList.find(item => item.trip == result.id)?.twoWheelerCapacity, label: 'Slots Available' },
                            { icon: CarIcon, count: itemList.find(item => item.trip == result.id)?.fourWheelerCapacity, label: 'Slots Available' },
                            { icon: LuggageIcon, count: itemList.find(item => item.trip == result.id)?.goodsCapacity, label: 'Qls Available' },
                        ]
                        return (
                            <React.Fragment key={result.id}>
                                <Card addClass={['mb-4']}>
                                    <div>
                                        <div className='flex justify-between flex-wrap items-center'>
                                            <div className='w-full lg:w-auto'>
                                                <h4 className="font-semibold text-sky-500">{result.vessel.name}</h4>
                                                <p className="text-sky-500 font-medium">{dateUtil.formatTime(result.departureTime)} | {route.ghatFrom.ghatName}</p>
                                            </div>
                                            <div className='w-full lg:w-auto mt-2'>
                                                <DefaultButton
                                                    buttonText='Book Tickets'
                                                    onClick={() => handleBookTicketClick(result.id)}
                                                />
                                            </div>
                                        </div>

                                        <hr className='my-2' />
                                        <div className="flex items-center justify-between mt-4">
                                            {
                                                itemSectionContents.map(((section, index) => (
                                                    <div key={index} className="flex items-center w-1/5 flex-col text-center lg:mx-8">
                                                        <div className='flex justify-center'>
                                                            <img className='w-8' src={section.icon} alt="Seat" />
                                                        </div>
                                                        <span className="text-lg text-neutral-600 font-semibold">{section.count}</span>
                                                        <span className="text-xs md:text-sm text-neutral-600">{section.label}</span>
                                                    </div>
                                                )))
                                            }
                                        </div>
                                    </div>
                                </Card>
                            </React.Fragment>
                        )
                    })
                }
            </div>
        </Body>
    )
}

export default SearchResults;