import AddRoundedIcon from '@mui/icons-material/AddRounded';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import MapIcon from '@mui/icons-material/Map';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { ChangeEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Body from '../../components/body/Body';
import DefaultButton from '../../components/buttons/DefaultButton';
import TableActionButton from '../../components/buttons/TableActionButton';
import Card from '../../components/cards/Card';
import FormControl from '../../components/forms/FormControl';
import ModalWrapper from '../../components/modals/ModalWrapper';
import apiUrl from '../../constants/api-constants';
import { useApiService } from '../../hooks/useApiService';
import { useEnumService } from '../../hooks/useEnumService';
import { handleError } from '../../services/handleError';

const GhatManagement: React.FC = () => {
    const { fetchAuthRequest, postAuthRequest } = useApiService();
    const { fetchStatusList } = useEnumService();

    const [ghats, setGhats] = useState<any[]>([]);
    const [ghatTableRows, setGhatTableRows] = useState<any[]>([]);
    const [divisionOptions, setDivisionOptions] = useState<any[]>([]);
    const [ghatId, setGhatId] = useState('');

    const [ghatName, setGhatName] = useState('');
    const [division, setDivision] = useState('');
    const [status, setStatus] = useState('');
    const [statusOptions, setStatusOptions] = useState<any[]>([]);
    const [divisionGlobal, setDivisionGlobal] = useState('');

    const [ghatModalOpen, setGhatModalOpen] = useState(false);

    const handleGhatModalOpen = () => setGhatModalOpen(true);
    const handleGhatModalClose = () => setGhatModalOpen(false);

    useEffect(() => {
        fetchDivisionOptions();
        fetchStatusOptions();
    }, []);

    const handleTableButtonClick = async (id: string, ghatName: string, division: string) => {
        setGhatId(id.toString());
        setGhatName(ghatName);
        setDivision(division);
        handleGhatModalOpen();
    };

    const handleAddButtonClick = async () => {
        setGhatId('');
        setGhatName('');
        setDivision(divisionOptions[0].value);
        handleGhatModalOpen();
    };

    const fetchStatusOptions = async () => {
        const options = await fetchStatusList();
        if (options) {
            setStatusOptions(options);
            setStatus(options[0]?.value);
        }
    }

    const fetchDivisionOptions = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.manage.division.getDivisions
            );

            if (response) {
                const { data } = response;
                const options: any = []
                data.forEach((division: any) => {
                    options.push({
                        value: division.id,
                        label: division.divisionName
                    })
                });

                setDivisionOptions(options);
                setDivision(options[0].value);
            }

        } catch (error) {
            handleError(error);
        }
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case 'ghatName':
                setGhatName(e.target.value);
                break;
            case 'division':
                setDivision(e.target.value);
                break;
            case 'status':
                setStatus(e.target.value);
                break;
            default:
                break;
        }
    }

    const fetchGhatsByDivision = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.manage.ghat.getGhatsByDivision,
                {
                    divisionId: division
                }
            );

            if (response) {
                const { data } = response;
                setGhats(ghats);

                const rows: any = [];

                data.forEach((ghat: any, index: number) => {
                    rows.push({
                        slNo: index + 1,
                        id: ghat.id,
                        ghatName: ghat.ghatName,
                        divisionId: ghat.division.id,
                        divisionName: ghat.division.divisionName,
                        status: ghat.status
                    })
                });

                setGhatTableRows(rows);
                setDivisionGlobal(division);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const handleFetchGhatsClick = async () => fetchGhatsByDivision();

    const ghatTableColumns: GridColDef[] = [
        { field: 'slNo', headerName: 'Sl No' },
        { field: 'ghatName', headerName: 'Ghat Name', type: 'string', flex: 2 },
        { field: 'divisionName', headerName: 'Division Name', type: 'string', flex: 2 },
        { field: 'status', headerName: 'Status', type: 'string', flex: 2 },
        {
            align: 'center',
            flex: 1,
            headerAlign: 'center',
            field: 'action',
            headerName: 'Action',
            sortable: false,
            filterable: false,
            renderCell: (params: any) => {
                const { id, ghatName, divisionId } = params.row;
                return (
                    <div className='flex gap-2 justify-center items-center h-full'>
                        <TableActionButton
                            type='primary'
                            onClick={() => handleTableButtonClick(id, ghatName, divisionId)}>
                            <BorderColorIcon fontSize='small' />
                        </TableActionButton>
                    </div>
                );
            },
        }
    ];

    const handleGhatSaveClick = async () => {
        try {
            const form = document.getElementById('ghatForm') as HTMLFormElement;
            if (form && form.checkValidity()) {
                const postData = {
                    id: ghatId,
                    ghatName: ghatName,
                    division: {
                        id: division,
                    },
                    status: status
                };
                const response: any = await postAuthRequest(
                    apiUrl.manage.ghat.saveGhat,
                    postData
                );

                if (response) {
                    const { status, message } = response.data;
                    toast.success(message);
                    fetchGhatsByDivision();
                    handleGhatModalClose();
                }
            } else {
                form && form.reportValidity();
            }
        } catch (error) {
            handleError(error);
        }
    }

    return (
        <Body>
            <div className='flex flex-wrap justify-between items-end'>
                <h3 className='flex justify-center items-center gap-4 mb-2'><MapIcon /> Ghat Management</h3>
            </div>
            <Card>
                <div className='flex w-full items-end justify-between gap-2'>
                    <div className='flex items-end gap-2'>
                        <div>
                            <FormControl
                                label='Select Division'
                                type={'select'}
                                id={'division'}
                                value={division}
                                onChange={handleInputChange}
                                options={divisionOptions}
                            />
                        </div>
                        <div className='mb-1'>
                            <DefaultButton
                                buttonText='Fetch Ghats'
                                onClick={handleFetchGhatsClick}
                                disabledTime={1}
                            />
                        </div>
                    </div>
                    <div className='flex w-max mt-4 mb-2'>
                        <DefaultButton
                            onClick={handleAddButtonClick}
                            buttonText='Add New Ghat'
                            disabledTime={1}
                            buttonIcon={AddRoundedIcon}
                        />
                    </div>
                </div>
            </Card>

            <div className='mt-3'>
                <div className='flex justify-center'>
                    <div className='flex w-full bg-white'>
                        <DataGrid
                            rows={ghatTableRows}
                            columns={ghatTableColumns}
                            autosizeOnMount
                            autoHeight
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15]}
                        />
                    </div>
                </div>
            </div>

            <ModalWrapper
                open={ghatModalOpen}
                handleClose={handleGhatModalClose}
                title={'Ghat Details'}
            >
                <form id='ghatForm' className='flex flex-wrap'>
                    <div className='w-full lg:w-2/4 lg:pr-4'>
                        <input type='text' id='id' name='id' value={ghatId} readOnly hidden />
                        <FormControl
                            label='Ghat Name'
                            type={'text'}
                            id={'ghatName'}
                            value={ghatName}
                            onChange={handleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/4 lg:pr-4'>
                        <input type='text' id='id' name='id' value={ghatId} readOnly hidden />
                        <FormControl
                            label='Division'
                            type={'select'}
                            id={'division'}
                            value={division}
                            options={divisionOptions}
                            onChange={handleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/4'>
                        <input type='text' id='id' name='id' value={ghatId} readOnly hidden />
                        <FormControl
                            label='Status'
                            type={'select'}
                            id={'status'}
                            value={status}
                            options={statusOptions}
                            onChange={handleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                </form>
                <div className='mt-4'>
                    <DefaultButton
                        onClick={handleGhatSaveClick}
                        buttonText={'Save Ghat Details'}
                    />
                </div>
            </ModalWrapper>
        </Body>
    )
}

export default GhatManagement