import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import Body from '../../components/body/Body';
import DefaultButton from '../../components/buttons/DefaultButton';
import Card from '../../components/cards/Card';
import FormControl from '../../components/forms/FormControl';
import apiUrl from '../../constants/api-constants';
import { ROUTES } from '../../constants/routes-constants';
import useAuth from '../../hooks/useAuth';
import { useApiService } from '../../hooks/useApiService';
import { handleError } from '../../services/handleError';
import { validationService } from '../../services/validationService';

import { store, useAppDispatch } from '../../store/reducers/store';
import { setAuthenticated } from '../../store/actions/data';
import { device } from '../../services/deviceDetailsService';

const Login: React.FC = () => {

    const { auth, setAuth } = useAuth();
    const dispatch = useAppDispatch();

    const { postPublicRequest } = useApiService();

    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || ROUTES.INDEX;

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [formErrors, setFormErrors] = useState<any>({});

    useEffect(() => {
        handleUsernameChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
        handlePasswordChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
    }, []);

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (validationService.isValidMobileInput(value)) {
            setUsername(e.target.value);
        }
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleLoginClick = async () => {
        await new Promise(resolve => setTimeout(resolve, 100));

        const form = document.getElementById('loginForm') as HTMLFormElement;
        if (form && form.checkValidity()) {
            try {
                setFormErrors({});
                const response: any = await postPublicRequest(apiUrl.auth.postSignIn, {
                    username: username,
                    password: password,
                    deviceType: device.getType(),
                    deviceAddress: device.getAddress()
                }, true);
                if (response) {
                    const { object } = response.data;
                    const { accessToken, tokenType, user } = object;

                    setAuth({
                        accessToken: accessToken,
                        tokenType: tokenType,
                        user: user,
                        roles: user.roles.map((x: any) => x.role)
                    });

                    dispatch(setAuthenticated(true));
                    toast.success("User authenticated successfully");
                    navigate(from, { replace: true });
                }
            } catch (error: any) {
                const errorList = handleError(error);
                if (errorList) {
                    setFormErrors(errorList);
                }
            }
        } else {
            form && form.reportValidity();
        }
    };

    return (
        <Body>
            <div className="w-full md:w-7/12 lg:w-5/12 flex justify-center mt-6 m-auto">
                <Card>
                    <div>
                        <h1 className="text-sky-600 text-xl font-medium text-center leading-tight tracking-tight md:text-2xl">
                            Sign in to your account
                        </h1>
                        <form className="space-y-4 md:space-y-6" id="loginForm">
                            <FormControl
                                label={'Your mobile number'}
                                type={'text'}
                                id={'mobileNo'}
                                value={username}
                                placeholder='e.g. 9876543210'
                                onChange={handleUsernameChange}
                                mandatoryField={true}
                                errorMessage={formErrors.mobileNo}
                            >
                            </FormControl>
                            <FormControl
                                label="Password"
                                type="password"
                                id="password"
                                value={password}
                                placeholder="••••••••"
                                onChange={handlePasswordChange}
                                mandatoryField={true}
                                errorMessage={formErrors.password}
                            />
                            <div className="flex items-center justify-end">
                                <button onClick={() => navigate(ROUTES.AUTH.FORGOT_PASSWORD)} className="text-sm font-medium text-sky-600 hover:underline">Forgot password?</button>
                            </div>

                            <DefaultButton
                                onClick={handleLoginClick}
                                buttonText="Login"
                                disabledTime={0}
                            />

                            <p className="text-sm font-light text-gray-500">
                                Don&apos;t have an account yet? <button onClick={() => navigate(ROUTES.AUTH.SIGN_UP)} className="font-medium text-sky-600 hover:underline">Sign up</button>
                            </p>
                        </form>
                    </div>
                </Card>
            </div>
        </Body>
    )
}

export default Login
