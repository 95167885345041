import React from 'react'
import appConstants from '../../constants/app-constants'
import { dateUtil } from '../../utility/dateUtil'

const DefaultFooter: React.FC = () => {
  return (
    <footer className="shadow mt-20 bg-white">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <span className="block text-sm text-neutral-700 sm:text-center">
          © {dateUtil.getCurrentYear()} | <a href={appConstants.orgWebsite}
            className="hover:underline"
            target='_blank'
            rel="noopener noreferrer">{appConstants.orgName}</a> | All Rights Reserved.
        </span>
      </div>
    </footer>
  )
}

export default DefaultFooter
