import AddRoundedIcon from '@mui/icons-material/AddRounded';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { ChangeEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Body from '../../components/body/Body';
import DefaultButton from '../../components/buttons/DefaultButton';
import TableActionButton from '../../components/buttons/TableActionButton';
import Card from '../../components/cards/Card';
import FormControl from '../../components/forms/FormControl';
import ModalWrapper from '../../components/modals/ModalWrapper';
import apiUrl from '../../constants/api-constants';
import { useApiService } from '../../hooks/useApiService';
import { useEnumService } from '../../hooks/useEnumService';
import { handleError } from '../../services/handleError';

const DivisionManagement: React.FC = () => {
    const { fetchAuthRequest, postAuthRequest } = useApiService();
    const { fetchStatusList } = useEnumService();

    const [divisions, setDivisions] = useState<any[]>([]);
    const [divisionTableRows, setDivisionTableRows] = useState<any[]>([]);
    const [divisionId, setDivisionId] = useState('');

    const [divisionName, setDivisionName] = useState('');
    const [status, setStatus] = useState('');
    const [statusOptions, setStatusOptions] = useState<any[]>([]);
    const [divisionModalOpen, setDivisionModalOpen] = useState(false);

    const handleDivisionModalOpen = () => setDivisionModalOpen(true);
    const handleDivisionModalClose = () => setDivisionModalOpen(false);

    useEffect(() => {
        fetchDivisions();
        fetchStatusOptions();
    }, []);

    const fetchStatusOptions = async () => {
        const options = await fetchStatusList();
        if (options) {
            setStatusOptions(options);
            setStatus(options[0]?.value);
        }
    }

    const handleTableButtonClick = async (id: string, divisionName: string) => {
        setDivisionId(id.toString());
        setDivisionName(divisionName);
        handleDivisionModalOpen();
    };

    const handleAddButtonClick = async () => {
        setDivisionId('');
        setDivisionName('');
        handleDivisionModalOpen();
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case 'divisionName':
                setDivisionName(e.target.value);
                break;
            case 'status':
                setStatus(e.target.value);
                break;
            default:
                break;
        }
    }

    const fetchDivisions = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.manage.division.getDivisions
            );

            if (response) {
                const { data } = response;
                setDivisions(divisions);

                const rows: any = [];

                data.forEach((division: any) => {
                    rows.push({
                        id: division.id,
                        divisionName: division.divisionName,
                        status: division.status
                    })
                });

                setDivisionTableRows(rows);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const divisionTableColumns: GridColDef[] = [
        { field: 'id', headerName: 'Sl No', flex: 4, minWidth: 50 },
        { field: 'divisionName', headerName: 'Division Name', type: 'string', flex: 4, minWidth: 150 },
        { field: 'status', headerName: 'Status', type: 'string', flex: 4, minWidth: 150 },
        {
            align: 'center',
            flex: 3,
            minWidth: 200,
            headerAlign: 'center',
            field: 'action',
            headerName: 'Action',
            sortable: false,
            filterable: false,
            renderCell: (params: any) => {
                const { id, divisionName } = params.row;
                return (
                    <div className='flex gap-2 justify-center items-center h-full'>
                        <TableActionButton
                            type='primary'
                            onClick={() => handleTableButtonClick(id, divisionName)}>
                            <BorderColorIcon fontSize='small' />
                        </TableActionButton>
                    </div>
                );
            },
        }
    ];

    const handleDivisionSaveClick = async () => {
        try {
            const form = document.getElementById('divisionForm') as HTMLFormElement;
            if (form && form.checkValidity()) {
                const postData = {
                    id: divisionId,
                    divisionName: divisionName,
                    status: status
                };
                const response: any = await postAuthRequest(
                    apiUrl.manage.division.saveDivision,
                    postData
                );
                if (response) {
                    const { status, message } = response.data;
                    toast.success(message);
                    fetchDivisions();
                    handleDivisionModalClose();
                }
            } else {
                form && form.reportValidity();
            }
        } catch (error) {
            handleError(error);
        }
    }

    return (
        <Body>
            <div className='flex flex-wrap justify-between items-center'>
                <h3 className='flex justify-center items-center gap-4'><DeviceHubIcon /> Division Management</h3>
            </div>
            <Card>
                <div className='flex w-full justify-end'>
                    <div className='flex w-max'>
                        <DefaultButton
                            onClick={handleAddButtonClick}
                            buttonText='Add New Division'
                            disabledTime={0}
                            buttonIcon={AddRoundedIcon}
                        />
                    </div>
                </div>
            </Card>

            <div className='mt-3 '>
                <div className='flex justify-center'>
                    <div className='flex w-full bg-white'>
                        <DataGrid
                            rows={divisionTableRows}
                            columns={divisionTableColumns}
                            autosizeOnMount
                            autoHeight
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15]}
                        />
                    </div>
                </div>
            </div>

            <ModalWrapper
                width='small'
                open={divisionModalOpen}
                handleClose={handleDivisionModalClose}
                title={'Division Details'}>
                <form id='divisionForm' className='flex flex-wrap'>
                    <div className='w-full lg:w-2/3 lg:pr-4'>
                        <input type='text' id='id' name='id' value={divisionId} readOnly hidden />
                        <FormControl
                            label='Division Name'
                            type={'text'}
                            id={'divisionName'}
                            value={divisionName}
                            onChange={handleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/3'>
                        <FormControl
                            label='Status'
                            type={'select'}
                            id={'status'}
                            value={status}
                            options={statusOptions}
                            onChange={handleInputChange}
                            mandatoryField={true}
                        />
                    </div>
                </form>
                <div className='mt-4'>
                    <DefaultButton
                        onClick={handleDivisionSaveClick}
                        buttonText={'Save Division Details'}
                    />
                </div>
            </ModalWrapper>
        </Body>
    )
}

export default DivisionManagement