import toast from "react-hot-toast";
import apiUrl from "../../../constants/api-constants";
import { SelectOption } from "../../../constants/type-constants";
import { useApiService } from "../../../hooks/useApiService";
import { useEnumService } from "../../../hooks/useEnumService";
import { handleError } from "../../../services/handleError";

export const useTripSettingsService = () => {

    const {
        fetchAllDivisions,
        fetchStatusList,
        fetchBookingModes,
        fetchServiceTypes
    } = useEnumService();

    const {
        postAuthRequest,
        fetchAuthRequest
    } = useApiService();

    const fetchDivisionOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        const options = await fetchAllDivisions();
        if (options) {
            updateState(options);
            setOption(options[0]?.value);
        }
    }

    const fetchStatusOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        const options: any = await fetchStatusList();
        if (options) {
            updateState(options);
            setOption(options[0]?.value);
        }
    }

    const fetchBookingModeOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        const options: any = await fetchBookingModes();
        if (options) {
            updateState(options);
            setOption(options[0]?.value);
        }
    }

    const fetchServiceTypeOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        const options: any = await fetchServiceTypes();
        if (options) {
            updateState(options);
            setOption(options[0]?.value);
        }
    }

    const fetchVesselsByDivision = async (
        division: string,
        setVesselOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>
    ) => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.manage.vessel.getVesselsByDivision,
                {
                    divisionId: division
                }
            );

            if (response) {
                const { data } = response;
                const options: SelectOption[] = [];
                data.forEach((vessel: any) => {
                    options.push({
                        value: vessel.id,
                        label: `${vessel.name} (${vessel.regNo})`
                    });
                });
                setVesselOptions(options);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const fetchRoutesByDivision = async (
        division: string,
        setGhatFromOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setGhatToOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setRoutes: React.Dispatch<React.SetStateAction<any[]>>,
    ) => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.manage.route.getRoutesByDivision,
                {
                    divisionId: division
                }
            );

            if (response) {
                const { data } = response;
                if (data.length == 0) {
                    setGhatFromOptions([]);
                    setGhatToOptions([]);
                }
                setRoutes(data);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const groupItemsByDeck = (items: any) => {
        const grouped = items.reduce((acc: any, item: any) => {
            const deckId = item.deck.id;
            if (!acc[deckId]) {
                acc[deckId] = {
                    deckId: deckId,
                    deckNo: item.deck.deckNo,
                    items: []
                };
            }
            acc[deckId].items.push({
                itemType: item.item.itemType,
                unitType: item.item.unitType,
                capacityValue: item.capacityValue
            });
            return acc;
        }, {});

        return Object.values(grouped);
    }

    const fetchTripSettings = async (
        routes: any[],
        ghatFrom: string,
        ghatTo: string,
        setTripSettingsTableRows: React.Dispatch<React.SetStateAction<any[]>>,
        setTripList: React.Dispatch<React.SetStateAction<any[]>>
    ) => {
        try {
            const routeData = routes.find(route => route.ghatFrom.id == ghatFrom && route.ghatTo.id == ghatTo);
            const response: any = await fetchAuthRequest(
                apiUrl.manage.tripSettings.getTripSettingsByRoute,
                {
                    routeId: routeData.id
                }
            );

            const { data } = response;
            const rows: any[] = [];
            data.forEach((trip: any, index: number) => {
                // const deckItems = groupItemsByDeck(trip.vessel.vesselDeckItems);
                rows.push({
                    slNo: index + 1,
                    id: trip.id,
                    route: `${trip.route.ghatFrom.ghatName} to ${trip.route.ghatTo.ghatName}`,
                    departureTime: trip.departureTime,
                    serviceType: trip.serviceType,
                    tripDuration: trip.tripDuration,
                    dockNo: trip.dockNo,
                    bookingMode: trip.bookingMode,
                    vessel: trip.vessel,
                    status: trip.status,
                    // deckItems: deckItems,
                    deckItems: [],
                })
            });

            setTripSettingsTableRows(rows);
            setTripList(data);
        } catch (error) {
            handleError(error);
        }
    }

    const saveTrip = async (
        postData: any,
    ) => {
        try {
            const response: any = await postAuthRequest(
                apiUrl.manage.tripSettings.saveTripSettings,
                postData
            );
            const { message } = response.data;
            toast.success(message);

            return true;
        } catch (error) {
            handleError(error);
            return false;
        }
    }

    return {
        fetchDivisionOptions,
        fetchStatusOptions,
        fetchBookingModeOptions,
        fetchServiceTypeOptions,
        fetchVesselsByDivision,
        fetchRoutesByDivision,
        fetchTripSettings,
        saveTrip,
    }
} 