const swalConfig = (config: object,) => {
    return {
        customClass: {
            confirmButton: `text-white bg-sky-600 hover:bg-sky-700 focus:ring-2 transition duration-300 
            focus:outline-none focus:ring-sky-300 font-medium rounded px-5 py-2 text-center mx-1.5 text-sm`,
            cancelButton: `text-white bg-slate-500 hover:bg-slate-600 focus:ring-2 transition duration-300 
            focus:outline-none focus:ring-slate-300 font-medium rounded px-5 py-2 text-center mx-1.5 text-sm`,
            title: `pt-0 text-2xl`,
            icon: `mb-2 mt-4 text-sm`,
            actions: `mt-3`,
        },
        buttonsStyling: false,
        ...config
    };
}

export default swalConfig;