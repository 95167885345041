import React, { useState } from 'react'
import Body from '../../components/body/Body'
import Card from '../../components/cards/Card';
import { Collapse } from '@mui/material';
import Ticket from '../../components/ticket/Ticket';

const MyBookings: React.FC = () => {
    
    return (
        <Body>
            <h3>My Bookings</h3>

            <Ticket id={'x'} />

            {/* <Card addClass={["relative"]}>
                <div className=''>
                    <div className='absolute roboto-mono bg-sky-600 text-white -rotate-90 left-0 w-100'>
                        TICKET
                    </div>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia, rem.
                </div>
            </Card> */}
        </Body>
    )
}

export default MyBookings