import React from 'react';
import Select, { ActionMeta } from 'react-select';
import { SelectOption } from '../../constants/type-constants';

interface SelectWrapperProps {
    label?: string;
    id: string;
    options: SelectOption[];
    onChange: (newValue: any, actionMeta: ActionMeta<any>) => void
    isMulti?: boolean;
    value?: any;
    defaultValue?: any;
    mandatoryField?: boolean
}

const SelectWrapper: React.FC<SelectWrapperProps> = ({ ...props }) => {
    return (
        <>
            {props.label &&
                <label htmlFor={props.id} className="block mb-2 text-sm font-medium text-gray-900">{props.label}  {props.mandatoryField && <span className="text-red-500">*</span>} </label>}
            <Select
                defaultValue={props.defaultValue}
                options={props.options}
                onChange={(newValue, actionMeta) => props.onChange(newValue, actionMeta)}
                inputId={props.id}
                name={props.id}
                isMulti={props.isMulti ? props.isMulti : false}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        backgroundColor: '#f8fafc',
                        borderColor: '#e2e8f0',
                        color: '#1a202c',
                        borderRadius: '0.5rem',
                        width: '100%',
                        marginTop: '-1px',
                        paddingLeft: '0.35rem',
                        paddingRight: '0.15rem',
                        paddingTop: '0.175rem',
                        paddingBottom: '0.15rem',
                    })
                }}
            >

            </Select>
        </>
    )
}

export default SelectWrapper