import React, { useState } from 'react'
import apiUrl from '../../constants/api-constants';
import { useApiService } from '../../hooks/useApiService';
import { handleError } from '../../services/handleError';
import Body from '../../components/body/Body';
import Card from '../../components/cards/Card';
import FormControl from '../../components/forms/FormControl';
import DefaultButton from '../../components/buttons/DefaultButton';
import { ROUTES } from '../../constants/routes-constants';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const PasswordOtpVerification: React.FC = () => {

    const { postPublicRequest } = useApiService();
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [formErrors, setFormErrors] = useState<any>({});

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case 'otp':
                if (e.target.value === '' || /^\d+$/.test(e.target.value)) {
                    setOtp(e.target.value);
                }
                break;
            case 'password':
                setPassword(e.target.value);
                break;
            case 'confirmPassword':
                setConfirmPassword(e.target.value);
                break;
            default:
                break;
        }
    }

    const verifyOtp = async () => {
        const form = document.getElementById('verifyOtpForm') as HTMLFormElement;
        if (form && form.checkValidity()) {
            const postData = {
                fullName: Cookies.get('fullName'),
                email: Cookies.get('email'),
                mobileNo: Cookies.get('mobileNo'),
                password: password,
                confirmPassword: confirmPassword,
                otp: otp
            }
            try {
                setFormErrors({});
                const response: any = await postPublicRequest(apiUrl.auth.postResetPassword, postData);
                if (response) {
                    const { message } = response.data;
                    toast.success(message);
                    setTimeout(() => navigate(ROUTES.AUTH.LOGIN));
                }

            } catch (error: any) {
                const formErrors = handleError(error);
                if (formErrors) {
                    setFormErrors(formErrors);
                }
            }
        } else {
            form && form.reportValidity();
        }
    }

    const resendOtp = async () => {
        const postData = {
            fullName: Cookies.get('fullName'),
            email: Cookies.get('email'),
            mobileNo: Cookies.get('mobileNo')
        }
        try {
            const response: any = await postPublicRequest(apiUrl.auth.postResendOtp, postData);
            if (response) {
                const { status, message } = response.data;
                status ? toast.success(message) : toast.error(message);
            }
        } catch (error) {
            handleError(error);
        }
    }

    return (
        <Body>
            <div className="w-full md:w-7/12 lg:w-5/12 flex justify-center m-auto">
                <Card>
                    <div>
                        <h1 className="text-sky-600 text-xl font-medium text-center leading-tight tracking-tight md:text-2xl">
                            Verify OTP
                        </h1>
                        <form className="space-y-2 md:space-y-3" id="verifyOtpForm">
                            <FormControl
                                label={'Enter OTP'}
                                type={'text'}
                                id={'otp'}
                                value={otp}
                                onChange={handleInputChange}
                                errorMessage={formErrors.otp}
                                mandatoryField={true}
                            />
                            <FormControl
                                label={'Password'}
                                type={'password'}
                                id={'password'}
                                value={password}
                                onChange={handleInputChange}
                                errorMessage={formErrors.password}
                                mandatoryField={true}
                            />
                            <FormControl
                                label={'Confirm Password'}
                                type={'password'}
                                id={'confirmPassword'}
                                value={confirmPassword}
                                onChange={handleInputChange}
                                errorMessage={formErrors.confirmPassword}
                                mandatoryField={true}
                            />
                            <br />
                        </form>
                        <DefaultButton
                            onClick={verifyOtp}
                            buttonText="Verify OTP"
                            disabledTime={0}
                        />

                        <p className="text-sm font-medium text-gray-500 my-3">
                            Haven&apos;t received? <button onClick={resendOtp} className="font-semibold text-sky-600 hover:underline">Resend OTP</button>
                        </p>
                    </div>
                </Card>
            </div>
        </Body>
    )
}

export default PasswordOtpVerification