import React, { useEffect, useState } from 'react'
import Body from '../../components/body/Body'
import { handleError } from '../../services/handleError';
import { useApiService } from '../../hooks/useApiService';
import apiUrl from '../../constants/api-constants';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import TableActionButton from '../../components/buttons/TableActionButton';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DefaultButton from '../../components/buttons/DefaultButton';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import FormControl from '../../components/forms/FormControl';
import { useEnumService } from '../../hooks/useEnumService';
import Card from '../../components/cards/Card';
import PopoverDetails from '../../components/popovers/PopoverDetails';
import { dateUtil } from '../../utility/dateUtil';
import ModalWrapper from '../../components/modals/ModalWrapper';
import toast from 'react-hot-toast';

const TripManagement: React.FC = () => {
    const { fetchAuthRequest, postAuthRequest } = useApiService();
    const {
        fetchStatusList,
        fetchBookingModes,
        fetchServiceTypes,
        fetchAllDivisions
    } = useEnumService();
    const [trip, setTrip] = useState('');
    const [tripList, setTripList] = useState<any[]>([]);

    const [ghatFrom, setGhatFrom] = useState('');
    const [ghatFromOptions, setGhatFromOptions] = useState<any[]>([]);

    const [ghatTo, setGhatTo] = useState('');
    const [ghatToOptions, setGhatToOptions] = useState<any[]>([]);

    const [tripDate, setTripDate] = useState(dateUtil.formatDate((new Date()).toString()));

    const [division, setDivision] = useState('');
    const [divisionOptions, setDivisionOptions] = useState<any[]>([]);

    const [statusOptions, setStatusOptions] = useState<any[]>([]);
    const [bookingModeOptions, setBookingModeOptions] = useState<any[]>([]);
    const [serviceTypeOptions, setServiceTypeOptions] = useState<any[]>([]);

    const [vesselOptions, setVesselOptions] = useState<any[]>([]);

    // ------------------------------------------------
    // ------------------- FORM -----------------------
    // ------------------------------------------------
    const [formGhatFrom, setFormGhatFrom] = useState('');
    const [formGhatFromOptions, setFormGhatFromOptions] = useState<any[]>([]);
    const [formGhatTo, setFormGhatTo] = useState('');
    const [formGhatToOptions, setFormGhatToOptions] = useState<any[]>([]);
    const [formTripDate, setFormTripDate] = useState('');
    const [vessel, setVessel] = useState('');
    const [departureHour, setDepartureHour] = useState('');
    const [departureMin, setDepartureMin] = useState('');
    const [departureSec, setDepartureSec] = useState('');
    const [tripDurationHour, setTripDurationHour] = useState('');
    const [tripDurationMin, setTripDurationMin] = useState('');
    const [tripDurationSec, setTripDurationSec] = useState('');
    const [tripStatus, setTripStatus] = useState('');
    const [dockNo, setDockNo] = useState('');
    const [bookingMode, setBookingMode] = useState('');
    const [activateBefore, setActivateBefore] = useState('');
    const [onlineSeat, setOnlineSeat] = useState('');
    const [offlineSeat, setOfflineSeat] = useState('');
    const [serviceType, setServiceType] = useState('');
    // ------------------------------------------------

    const [routes, setRoutes] = useState<any[]>([]);
    const [tripModalOpen, setTripModalOpen] = useState(false);
    const handleTripModalOpen = () => setTripModalOpen(true);
    const handleTripModalClose = () => setTripModalOpen(false);

    const [tripTableRows, setTripTableRows] = useState<any[]>([]);
    const tripTableCols: GridColDef[] = [
        { field: 'slNo', headerName: 'Sl No' },
        { field: 'vessel', headerName: 'Vessel', flex: 2 },
        { field: 'departureTime', headerName: 'Dept. Time', flex: 2 },
        { field: 'tripDuration', headerName: 'Duration', flex: 2 },
        { field: 'dockNo', headerName: 'Dock No', flex: 2 },
        { field: 'status', headerName: 'Status', flex: 2 },
        { field: 'serviceType', headerName: 'Service Type', flex: 2 },
        {
            align: 'center',
            flex: 2,
            minWidth: 150,
            headerAlign: 'center',
            field: 'capacity',
            headerName: 'Capacity',
            sortable: false,
            filterable: false,
            renderCell: (params: any) => {
                const { id, deckItems } = params.row;
                return (
                    <PopoverDetails buttonText={'View Capacity'}>
                        <table key={id} className='w-full'>
                            <tbody className='text-sm'>
                                {
                                    deckItems.map((deck: any) => (
                                        <React.Fragment key={deck.deckId}>
                                            <tr className='border bg-sky-100'>
                                                <td colSpan={2} className='px-2 py-1'>{deck.deckNo}</td>
                                            </tr>
                                            {
                                                deck.items.map((item: any) => (
                                                    <tr key={item.itemType}>
                                                        <td className='w-3/4 border px-2 py-1'>{item.itemType} ({item.unitType})</td>
                                                        <td className='w-1/4 border px-2 py-1'>{item.capacityValue}</td>
                                                    </tr>
                                                ))
                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </tbody>
                        </table>
                    </PopoverDetails>
                );
            },
        },
        {
            align: 'center',
            flex: 2,
            minWidth: 150,
            headerAlign: 'center',
            field: 'action',
            headerName: 'Action',
            sortable: false,
            filterable: false,
            renderCell: (params: any) => {
                const { id } = params.row;
                return (
                    <div className='flex gap-2 justify-center items-center h-full'>
                        <TableActionButton
                            type='primary'
                            onClick={async () => handleTripEditClick(id)}>
                            <BorderColorIcon fontSize='small' />
                        </TableActionButton>
                    </div>
                );
            },
        }
    ]

    useEffect(() => {
        getStatusList();
        getBookingModes();
        getServiceTypes();
        getAllDivisions();
    }, []);

    useEffect(() => {
        if (division != '') {
            fetchVesselsByDivision();
            fetchRoutesByDivision();
        }
    }, [division]);

    useEffect(() => {
        if (routes.length > 0) {
            generateGhatFromOptions();
        }
    }, [routes]);

    useEffect(() => {
        if (ghatFrom != '') {
            generateGhatToOptions();
        }
    }, [ghatFrom]);

    useEffect(() => {
        if (formGhatFrom != '') {
            generateFormGhatToOptions();
        }
    }, [formGhatFrom]);

    const getStatusList = async () => {
        const options: any = await fetchStatusList();
        if (options) {
            setStatusOptions(options);
            setTripStatus(options[0]?.value);
        }
    }

    const getBookingModes = async () => {
        const options: any = await fetchBookingModes();
        if (options) {
            setBookingModeOptions(options);
            setBookingMode(options[0]?.value);
        }
    }

    const getServiceTypes = async () => {
        const options: any = await fetchServiceTypes();
        if (options) {
            setServiceTypeOptions(options);
            setServiceType(options[0]?.value);
        }
    }

    const getAllDivisions = async () => {
        const options: any = await fetchAllDivisions();
        if (options) {
            setDivisionOptions(options);
            setDivision(options[0]?.value);
        }
    }

    const fetchVesselsByDivision = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.manage.vessel.getVesselsByDivision,
                {
                    divisionId: division
                }
            );

            if (response) {
                const { data } = response;
                const options: { value: string; label: string }[] = [];
                data.forEach((vessel: any) => {
                    options.push({
                        value: vessel.id,
                        label: `${vessel.name} (${vessel.regNo})`
                    });
                });
                setVesselOptions(options);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const generateGhatFromOptions = () => {
        const ghats: any = [];
        routes.forEach((route: any) => {
            if (!ghats.find((ghat: any) => ghat.value == route.ghatFrom.id)) {
                ghats.push({
                    value: route.ghatFrom.id,
                    label: route.ghatFrom.ghatName
                })
            }
        });
        setGhatFromOptions(ghats);
        setGhatFrom(ghats[0]?.value);
    }

    const generateGhatToOptions = () => {
        const ghats: any = [];
        routes.forEach((route: any) => {
            if (!ghats.find((ghat: any) => ghat.value == route.ghatTo.id)) {
                if (route.ghatFrom.id == ghatFrom) {
                    ghats.push({
                        value: route.ghatTo.id,
                        label: route.ghatTo.ghatName
                    })
                }
            }
        });
        setGhatToOptions(ghats);
        setGhatTo(ghats[0]?.value);
    }

    const generateFormGhatFromOptions = () => {
        const ghats: any = [];
        routes.forEach((route: any) => {
            if (!ghats.find((ghat: any) => ghat.value == route.ghatFrom.id)) {
                ghats.push({
                    value: route.ghatFrom.id,
                    label: route.ghatFrom.ghatName
                })
            }
        });
        setFormGhatFromOptions(ghats);
        setFormGhatFrom(ghats[0]?.value);
    }

    const generateFormGhatToOptions = () => {
        const ghats: any = [];
        routes.forEach((route: any) => {
            if (!ghats.find((ghat: any) => ghat.value == route.ghatTo.id)) {
                if (route.ghatFrom.id == formGhatFrom) {
                    ghats.push({
                        value: route.ghatTo.id,
                        label: route.ghatTo.ghatName
                    })
                }
            }
        });
        setFormGhatToOptions(ghats);
        setFormGhatTo(ghats[0]?.value);
    }

    const getRouteId = () => {
        let routeId = '';
        routes.forEach((route: any) => {
            if (route.ghatFrom.id == ghatFrom && route.ghatTo.id == ghatTo) {
                routeId = route.id
                return;
            }
        });
        return routeId;
    }

    const fetchRoutesByDivision = async () => {
        try {
            const response: any = await fetchAuthRequest(
                apiUrl.manage.route.getRoutesByDivision,
                {
                    divisionId: division
                }
            );

            if (response) {
                const { data } = response;
                if (data.length == 0) {
                    setGhatFromOptions([]);
                    setGhatToOptions([]);
                }
                setRoutes(data);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const handleFetchRoute = async () => fetchTrips();

    // -----------------------------------------
    // --------------- EDIT --------------------
    // -----------------------------------------
    const handleTripEditClick = (id: any) => {
        generateFormGhatFromOptions();
        const tripData = tripList.find((trip: any) => trip.id == id);
        if (tripData) {
            const [depH, depM, depS] = tripData.departureTime.split(':');
            const [durH, durM, durS] = tripData.tripDuration.split(':');
            setTrip(id);
            setFormGhatFrom(tripData.route.ghatFrom.id);
            setFormGhatTo(tripData.route.ghatTo.id);
            setFormTripDate(tripData.tripDate);
            setVessel(tripData.vessel.id);
            setDepartureHour(depH);
            setDepartureMin(depM);
            setDepartureSec(depS);
            setTripDurationHour(durH);
            setTripDurationMin(durM);
            setTripDurationSec(durS);
            setTripStatus(tripData.status);
            setDockNo(tripData.dockNo);
            setBookingMode(tripData.bookingMode || bookingMode);
            setServiceType(tripData.serviceType || serviceType)
            setActivateBefore(tripData.activateBefore);
            setOnlineSeat(tripData.onlineSeat);
            setOfflineSeat(tripData.offlineSeat);
            handleTripModalOpen();
        }
    }

    const groupItemsByDeck = (items: any) => {
        const grouped = items.reduce((acc: any, item: any) => {
            const deckId = item.deck.id;
            if (!acc[deckId]) {
                acc[deckId] = {
                    deckId: deckId,
                    deckNo: item.deck.deckNo,
                    items: []
                };
            }
            acc[deckId].items.push({
                itemType: item.item.itemType,
                unitType: item.item.unitType,
                capacityValue: item.capacityValue
            });
            return acc;
        }, {});

        return Object.values(grouped);
    }

    const fetchTrips = async () => {
        try {
            const routeId = getRouteId();

            const response: any = await fetchAuthRequest(
                apiUrl.manage.trip.getTrips,
                {
                    routeId: routeId,
                    tripDate: tripDate
                }
            );

            if (response) {
                const { data } = response;

                const rows: any[] = [];
                data.forEach((trip: any, index: number) => {
                    const deckItems = groupItemsByDeck(trip.tripDeckItems);
                    rows.push({
                        slNo: index + 1,
                        id: trip.id,
                        route: `${trip.route.ghatFrom.ghatName} to ${trip.route.ghatTo.ghatName}`,
                        tripDate: trip.tripDate,
                        departureTime: trip.departureTime,
                        tripDuration: trip.tripDuration,
                        dockNo: trip.dockNo,
                        bookingMode: trip.bookingMode,
                        serviceType: trip.serviceType,
                        vessel: trip.vessel.name,
                        onlineSeat: trip.onlineSeat,
                        offlineSeat: trip.offlineSeat,
                        status: trip.status,
                        deckItems: deckItems
                    })
                });

                setTripTableRows(rows);
                setTripList(data);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const val = e.target.value;
        switch (e.target.name) {
            case 'division':
                setDivision(val);
                break;
            case 'ghatFrom':
                setGhatFrom(val);
                break;
            case 'ghatTo':
                setGhatTo(val);
                break;
            case 'tripDate':
                setTripDate(val);
                break;
            default:
                break;
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const val = e.target.value;
        switch (e.target.name) {
            case 'formGhatFrom':
                setFormGhatFrom(val);
                break;
            case 'formGhatTo':
                setGhatTo(val);
                break;
            case 'formTripDate':
                setFormTripDate(val);
                break;
            case 'formVessel':
                setVessel(val);
                break;
            case 'departureHour':
                setDepartureHour(val);
                break;
            case 'departureMin':
                setDepartureMin(val);
                break;
            case 'departureSec':
                setDepartureSec(val);
                break;
            case 'durationHour':
                setTripDurationHour(val);
                break;
            case 'durationMin':
                setTripDurationMin(val);
                break;
            case 'durationSec':
                setTripDurationSec(val);
                break;
            case 'tripStatus':
                setTripStatus(val);
                break;
            case 'dockNo':
                setDockNo(val);
                break;
            case 'bookingMode':
                setBookingMode(val);
                break;
            case 'serviceType':
                setServiceType(val);
                break;
            case 'activateBefore':
                setActivateBefore(val);
                break;
            case 'onlineSeat':
                setOnlineSeat(val);
                break;
            case 'offlineSeat':
                setOfflineSeat(val);
                break;
            default:
                break;
        }
    }

    const handleTripSaveClick = async () => {
        try {
            const tripRoute = routes.find((route: any) => route.ghatFrom.id == formGhatFrom && route.ghatTo.id == formGhatTo);

            const postData = {
                id: trip,
                route: {
                    id: tripRoute.id
                },
                vessel: {
                    id: vessel
                },
                tripDate: formTripDate,
                departureTime: `${departureHour}:${departureMin}:${departureSec}`,
                tripDuration: `${tripDurationHour}:${tripDurationMin}:${tripDurationSec}`,
                activateBefore: activateBefore,
                dockNo: dockNo,
                bookingMode: bookingMode,
                serviceType: serviceType,
                onlineSeat: Number(onlineSeat),
                offlineSeat: Number(offlineSeat),
                status: tripStatus
            }

            const response: any = await postAuthRequest(
                apiUrl.manage.trip.updateTrip,
                postData
            );

            if (response) {
                const { message } = response.data;
                toast.success(message);
                fetchTrips();
            }

        } catch (error) {
            handleError(error);
        }
    }

    return (
        <Body>
            <div className='flex flex-wrap justify-between items-end'>
                <h3 className='flex justify-center items-center gap-4 mb-2'><ModeOfTravelIcon /> Trip Management</h3>
            </div>
            <Card >
                <div className='flex gap-2 items-end'>
                    <div className='w-full lg:w-1/5'>
                        <FormControl
                            label='Division'
                            type={'select'}
                            id={'division'}
                            value={division}
                            options={divisionOptions}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <div className='w-full lg:w-1/5'>
                        <FormControl
                            label='From'
                            type={'select'}
                            id={'ghatFrom'}
                            value={ghatFrom}
                            options={ghatFromOptions}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <div className='w-full lg:w-1/5'>
                        <FormControl
                            label='To'
                            type={'select'}
                            id={'ghatTo'}
                            value={ghatTo}
                            options={ghatToOptions}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <div className='w-full lg:w-1/5'>
                        <FormControl
                            label='Date'
                            type={'date'}
                            id={'tripDate'}
                            value={tripDate}
                            onChange={handleFilterChange}
                        />
                    </div>
                    <div className='w-full lg:w-1/5 pb-1'>
                        <DefaultButton
                            onClick={handleFetchRoute}
                            buttonText={'Fetch Trips'}
                            disabledTime={1}
                        />
                    </div>
                </div>
            </Card>
            <div className='mt-3'>
                <div className='flex justify-center'>
                    <div className='flex w-full'>
                        <DataGrid
                            rows={tripTableRows}
                            columns={tripTableCols}
                            autosizeOnMount
                            autoHeight
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                },
                            }}
                            pageSizeOptions={[5, 10, 15]}
                        />
                    </div>
                </div>
            </div>
            <ModalWrapper
                open={tripModalOpen}
                handleClose={handleTripModalClose}
                title={'Trip Details'}>
                <div>
                    <form className='flex flex-wrap'>
                        <input type="text" value={trip} readOnly hidden />
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <FormControl
                                mandatoryField={true}
                                label='From'
                                type={'select'}
                                id={'formGhatFrom'}
                                value={formGhatFrom}
                                onChange={handleInputChange}
                                options={formGhatFromOptions}
                            />
                        </div>
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <FormControl
                                mandatoryField={true}
                                label='To'
                                type={'select'}
                                id={'formGhatTo'}
                                value={formGhatTo}
                                onChange={handleInputChange}
                                options={formGhatToOptions}
                            />
                        </div>
                        <div className='w-full lg:w-1/3'>
                            <FormControl
                                mandatoryField={true}
                                label='Trip Date'
                                type={'date'}
                                id={'formTripDate'}
                                value={formTripDate}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <FormControl
                                mandatoryField={true}
                                label='Vessel'
                                type={'select'}
                                id={'formVessel'}
                                value={vessel}
                                options={vesselOptions}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <label
                                className='block mb-2 text-sm font-medium text-gray-900'
                                htmlFor='departureHour'
                            >
                                Departure Time
                            </label>
                            <div className='flex'>
                                <div className='w-1/3 pr-2'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'departureHour'}
                                        placeholder='HH'
                                        value={departureHour}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='w-1/3 pr-2'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'departureMin'}
                                        placeholder='MM'
                                        value={departureMin}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='w-1/3'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'departureSec'}
                                        placeholder='SS'
                                        value={departureSec}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:w-1/3'>
                            <label
                                className='block mb-2 text-sm font-medium text-gray-900'
                                htmlFor='durationHour'
                            >
                                Duration
                            </label>
                            <div className='flex'>
                                <div className='w-1/3 pr-2'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'durationHour'}
                                        placeholder='HH'
                                        value={tripDurationHour}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='w-1/3 pr-2'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'durationMin'}
                                        placeholder='MM'
                                        value={tripDurationMin}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className='w-1/3'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'durationSec'}
                                        placeholder='SS'
                                        value={tripDurationSec}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <FormControl
                                mandatoryField={true}
                                label='Service Type'
                                type={'select'}
                                id={'serviceType'}
                                value={serviceType}
                                options={serviceTypeOptions}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <FormControl
                                mandatoryField={true}
                                label='Status'
                                type={'select'}
                                id={'tripStatus'}
                                value={tripStatus}
                                options={statusOptions}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-full lg:w-1/3'>
                            <FormControl
                                mandatoryField={true}
                                label='Dock No'
                                type={'text'}
                                id={'dockNo'}
                                value={dockNo}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <FormControl
                                mandatoryField={true}
                                label='Booking Mode'
                                type={'select'}
                                id={'bookingMode'}
                                value={bookingMode}
                                options={bookingModeOptions}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <FormControl
                                mandatoryField={true}
                                label='Online Seat'
                                type={'number'}
                                id={'onlineSeat'}
                                value={onlineSeat}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-full lg:w-1/3'>
                            <FormControl
                                mandatoryField={true}
                                label='Offline Seat'
                                type={'number'}
                                id={'offlineSeat'}
                                value={offlineSeat}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-full lg:w-1/3 lg:pr-4'>
                            <FormControl
                                mandatoryField={true}
                                label='Activate Before (hours)'
                                type={'number'}
                                id={'activateBefore'}
                                value={activateBefore}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='w-full mt-4'>
                            <DefaultButton
                                onClick={handleTripSaveClick}
                                buttonText={'Save Trip Details'}
                            />
                        </div>
                    </form>
                </div>
            </ModalWrapper>
        </Body>
    )
}

export default TripManagement