import axios from 'axios';
import apiUrl from '../constants/api-constants';
import { useLoading } from '../contexts/LoadingContext';
import useAuth from './useAuth';
import { useTokenRefresh } from './useTokenRefresh';
import { useLogout } from './useLogout';

const useAxiosInterceptor = () => {
    const { refreshToken } = useTokenRefresh();
    const { startLoading, stopLoading, isLoading } = useLoading();

    const axiosInstance = axios.create({
        baseURL: apiUrl.BASE_URL,
    });

    let isRefreshing = false;
    let failedQueue: Array<any> = [];

    const processQueue = (error: any, token: string | null = null) => {
        failedQueue.forEach((prom: any) => {
            if (token) {
                prom.resolve(token);
            } else {
                prom.reject(error);
            }
        });
        failedQueue = [];
    };

    // Request interceptor to add the authorization header
    axiosInstance.interceptors.request.use(
        (config) => {
            if (!config.url?.includes('/common/') || !config.url?.includes('/auth/refreshtoken')) {
                // startLoading();
                stopLoading();
            }
            return config;
        },
        (error) => {
            stopLoading();
            Promise.reject(error)
        }
    );

    // Response interceptor to handle token refresh
    axiosInstance.interceptors.response.use(
        (response) => {
            stopLoading();
            return response
        },
        async (error) => {
            const { response, config } = error;
            const originalRequest = config;

            if (response && response.data.errorType === "INVALID_SESSION") {
                if (!isRefreshing) {
                    isRefreshing = true;
                    try {
                        const { accessToken, tokenType } = await refreshToken();

                        // Process the queued requests with the new token
                        processQueue(null, `${tokenType} ${accessToken}`);
                        isRefreshing = false;

                        // Retry the original request with the new token
                        originalRequest.headers['Authorization'] = `${tokenType} ${accessToken}`;
                        const result = await axiosInstance(originalRequest);

                        stopLoading();
                        return result;

                    } catch (refreshError) {
                        processQueue(refreshError, null);
                        isRefreshing = false;
                        stopLoading();
                        return Promise.reject(refreshError);
                    }
                }

                // If a token refresh is already happening, queue the requests until it's done
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                }).then((token) => {
                    originalRequest.headers['Authorization'] = token;
                    return axiosInstance(originalRequest);
                }).catch((err) => {
                    stopLoading();
                    return Promise.reject(err);
                });
            }

            stopLoading();
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

export default useAxiosInterceptor;
