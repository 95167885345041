import apiUrl from "../../../constants/api-constants";
import { SelectOption, VesselLayoutSlot } from "../../../constants/type-constants";
import { useApiService } from "../../../hooks/useApiService";
import { useEnumService } from "../../../hooks/useEnumService"
import { handleError } from "../../../services/handleError";

const itemBorder: any = {
    'Female': 'border-pink-500',
    'PWD': 'border-blue-500',
    'Senior Citizen': 'border-yellow-500',
    'Unreserved': 'border-gray-500',
    'Goods': 'border-green-500',
    'Bicycle': 'border-teal-500',
    'Two Wheeler': 'border-purple-500',
    'Four Wheeler': 'border-red-500',
};

const getItemBorderClass = (itemName: string | undefined) => {
    if (itemName) {
        return itemBorder[itemName] || 'border-neutral-300';
    }
    return 'border-neutral-300';
};

export const useVesselMatrixService = () => {

    const {
        fetchAllDecks,
        fetchAllItems,
    } = useEnumService();

    const {
        fetchAuthRequest,
        postAuthRequest
    } = useApiService();

    const fetchDeckOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const options = await fetchAllDecks();
            updateState(options);
            setOption(options[0]?.value);

        } catch (error) {
            handleError(error);
        }
    }

    const fetchItemOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>
    ) => {
        try {
            const response: any = await fetchAllItems();
            if (response) {
                const { data } = response;
                const itemList: any = []
                data.forEach((item: any) => {
                    if (item.itemType == "Unreserved") {
                        itemList.unshift({
                            value: item.id,
                            label: item.itemType
                        });
                    } else {
                        itemList.push({
                            value: item.id,
                            label: item.itemType
                        });
                    }

                });
                updateState(itemList);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const fetchVesselLayout = async (
        vesselId: string,
        deckId: string,
        // updateState: React.Dispatch<React.SetStateAction<any>>
    ) => {
        try {
            const response = await fetchAuthRequest(apiUrl.manage.vesselLayout.getVesselsLayout,
                {
                    vesselId: vesselId,
                    deckId: deckId
                }
            );
            const { data } = response;
            const processedData: VesselLayoutSlot[] = []

            data.vesselLayoutSlots.forEach((slot: any) => {
                processedData.push({
                    rowNo: slot.rowNo,
                    columnNo: slot.columnNo,
                    seatNo: slot.seatNo,
                    borderColor: getItemBorderClass(slot.item.itemType),
                    item: {
                        id: slot.item.id,
                        itemName: slot.item.itemType,
                    }
                });
            });
            // updateState(processedData);

            return processedData;

        } catch (error) {
            handleError(error);

            return [];
        }
    }

    return {
        fetchDeckOptions,
        fetchItemOptions,
        fetchVesselLayout,
    }
}