import React, { ReactNode } from 'react'

interface PrimaryButtonProps {
    onClick: any
    buttonText: ReactNode | string
    fullWidth?: boolean
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ onClick, buttonText, fullWidth }) => {
    return (
        <button className={`text-white bg-sky-600 hover:bg-sky-700 focus:ring-4 
            transition duration-300 
            focus:outline-none focus:ring-sky-300 font-medium rounded-lg px-5 py-2.5 text-center 
            ${fullWidth ? `w-full` : ``}
            `}
            onClick={onClick}
        >
            {buttonText}
        </button>
    )
}

export default PrimaryButton