import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Body from '../../../components/body/Body';
import DefaultButton from '../../../components/buttons/DefaultButton';
import Card from '../../../components/cards/Card';
import FormControl from '../../../components/forms/FormControl';
import ModalWrapper from '../../../components/modals/ModalWrapper';
import { ROUTES } from '../../../constants/routes-constants';
import { SelectOption, VesselLayoutSlot } from '../../../constants/type-constants';
import VesselBg from '../../../resources/images/vessel/boat_bg.png';
import FourWheelerAvailable from '../../../resources/images/vessel/four_wheeler_available.svg';
import SeatAvailable from '../../../resources/images/vessel/seat_available.svg';
import { useVesselMatrixService } from './useVesselMatrixService';
import Layout, { getItemBorderClass } from './Layout';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

const VesselMatrix: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const { vesselId, divisionId } = location.state || {};

    const {
        fetchDeckOptions,
        fetchVesselLayout,
        fetchItemOptions
    } = useVesselMatrixService();

    const [rows, setRows] = useState(0);
    const [columns, setColumns] = useState(0);
    const [deck, setDeck] = useState('');
    const [deckOptions, setDeckOptions] = useState<SelectOption[]>([]);
    const [itemList, setItemList] = useState<any[]>([]);

    const [vesselLayoutSlots, setVesselLayoutSlots] = useState<VesselLayoutSlot[]>([]);

    const [matrix, setMatrix] = useState<ReactJSXElement>(<></>);

    useEffect(() => {
        fetchDeckOptions(setDeckOptions, setDeck);
        fetchItemOptions(setItemList)
    }, []);

    useEffect(() => {
        if (vesselId != '' && deck != '') {
            executeFetchVesselLayout();
        }
    }, [vesselId, deck]);

    const executeFetchVesselLayout = async () => {
        const data = await fetchVesselLayout(vesselId, deck);
        if (data.length > 0) {
            let maxRows = 0;
            let maxCols = 0;
            data.forEach(slot => {
                maxRows = slot.rowNo > maxRows ? slot.rowNo : maxRows;
                maxCols = slot.columnNo > maxCols ? slot.columnNo : maxCols;
            });

            maxRows = maxRows + 1;
            maxCols = maxCols + 1;

            setRows(maxRows);
            setColumns(maxCols);

            // Called with these params because state is not updated yet.
            setVesselLayoutSlots(data);
            handleGenerateLayoutButtonClick(maxRows, maxCols, data);
        }
    }

    useEffect(() => {
        const vesselLayout = [];
        for (let i = 0; i < rows; i++) {
            for (let j = 0; j < columns; j++) {
                const slot = vesselLayoutSlots.find(slot => slot.rowNo == i && slot.columnNo == j);
                vesselLayout.push({
                    rowNo: i,
                    columnNo: j,
                    seatNo: slot?.seatNo || '',
                    borderColor: slot?.seatNo != '' ? getItemBorderClass(slot?.item.itemName) : 'border-neutral-300',
                    item: {
                        id: slot?.item.id || itemList[0].value,
                        itemName: slot?.item.itemName || itemList[0].label
                    }
                })
            }
        }
        setVesselLayoutSlots(vesselLayout);
    }, [rows, columns])

    const handleNumberInputChange = (updateState: React.Dispatch<React.SetStateAction<number>>) =>
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            const value = parseInt(event.target.value);
            updateState(value);
        };

    const handleTextInputChange = (updateState: React.Dispatch<React.SetStateAction<string>>) =>
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            updateState(event.target.value);
        };

    const handleGenerateLayoutButtonClick = async (rows: number, columns: number, vesselLayoutSlots: VesselLayoutSlot[]) => {
        setMatrix(
        <Layout
            rows={rows}
            columns={columns}
            items={itemList}
            vesselId={vesselId}
            deck={deck}
            slots={vesselLayoutSlots}
        />
    );
    }

    return (
        <Body>
            <div className='flex items-center justify-between'>
                <h2>Vessel Matrix - Seat Layout</h2>
                <div className='cursor-pointer font-medium' onClick={() => navigate(ROUTES.MANAGE.VESSEL, { state: { divisionId: divisionId } })}>
                    Back
                </div>
            </div>
            <div className='mb-4'>
                <Card>
                    <div className='grid grid-cols-4 gap-4 items-end'>
                        <FormControl
                            placeholder='Rows'
                            label='Rows'
                            type={'number'}
                            id={'rows'}
                            value={rows}
                            onChange={handleNumberInputChange(setRows)}
                        />
                        <FormControl
                            placeholder='Columns'
                            label='Columns'
                            type={'number'}
                            id={'columns'}
                            value={columns}
                            onChange={handleNumberInputChange(setColumns)}
                        />
                        <FormControl
                            placeholder='Deck No'
                            label='Select Deck'
                            type={'select'}
                            id={'deckNo'}
                            value={deck}
                            options={deckOptions}
                            onChange={handleTextInputChange(setDeck)}
                        />
                        <div className='mb-1'>
                            <DefaultButton
                                onClick={async () => {
                                    handleGenerateLayoutButtonClick(rows, columns, vesselLayoutSlots)
                                }}
                                buttonText={'Generate Layout'}
                                disabledTime={1}
                            />
                        </div>
                    </div>
                </Card>
            </div>
            <div className='mb-4'>
                <Card>
                    
                        {matrix}
                    
                    {/* <DefaultButton
                        onClick={async () => { }}
                        buttonText={'Matrix'}
                        disabledTime={1}
                    /> */}
                </Card>
            </div>

            {/* PREVIEW */}
        </Body>
    );
};

export default VesselMatrix;
